'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Case, Switch, Default} from "react-if";

import { States } from '@uw-it-sis/lib-react/lib/AppConstants';
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import ReleasesStore from "./ReleasesStore";
import ReleasesTable from './tables/ReleasesTable';
import TableCellFormatter from './tables/TableCellFormatter';
import LineItem from './tables/LineItem';
import ReleaseForm from './forms/ReleaseForm';
import ActionUtils from '../utils/ActionUtils';
import ActionPopupButton from '../components/ActionPopupButton';

class FutureTab extends Component {

    static propTypes = {
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object.isRequired,
        releases: PropTypes.object.isRequired,
    }

    render() {
        let formatter = new FutureFormatter(this.props.collective, this.props.blocCode);
        return (
            /* Render the first <Case> whose condition matches, or <Default>
             *
             * This is set up so that the page doesn't jump around on a RE-load, but shows a spinner on the first load.
             */
            <Loader show={ReleasesStore.state == States.pending}>
                <div className="mb-3">
                    <ActionPopupButton action={ActionUtils.Actions.NEW_RELEASE}>
                        <ReleaseForm
                            mode="add"
                            release={null}
                            collective={this.props.collective}
                            blocCode={this.props.blocCode} />
                    </ActionPopupButton>
                </div>
                <Switch>

                    <Case condition={this.props.releases.releases.length == 0}>
                        <div>No future releases are defined</div>
                    </Case>

                    <Default>
                        <ReleasesTable
                            data={this.props.releases}
                            formatter={formatter}
                            moduleColumnFormatter={formatter.formatModuleColumn} />
                    </Default>

                </Switch>
            </Loader>
        )
    }
}

/*
 * Formatter for cells in the Future releases table
 */
class FutureFormatter extends TableCellFormatter {

    constructor(collective, blocCode) {
        super();
        this.blocCode = blocCode;
        this.collective = collective;
    }

    /*
     * Returns the JSX to render for a release info cell, the top row in the table
     */
    formatReleaseInfo(release, row, rowIndex, formatExtraData) {
        const description = this.buildDescriptionLine(release);

        const actionButtons = ActionUtils.buildReleaseActionButtons(release, this.collective, this.blocCode);
        const errors = this.buildErrors(release);
        const warnings = this.buildWarnings(release);

        return (
            <>
                <ul className="tabular-data">
                    <LineItem name="Collective Version:" val={release.productVersion} />
                    <LineItem name="Release Date:" val={release.releaseDate} />
                    {description}
                </ul>
                {actionButtons}
                {warnings}
                {errors}
            </>
        );
    }

    /*
     * Returns the JSX to render for a module version cell
     */
    formatModuleVersion(module, row, rowIndex, formatExtraData) {
        let version = this.buildModuleVersionText(module);
        let description = this.buildDescriptionLine(module);
        let errors = this.buildErrors(module);
        let warnings = this.buildWarnings(module);
        let actionButtons =
            ActionUtils.buildModuleActionButtons(module, formatExtraData.release, this.collective, this.blocCode);

        return (
            <>
                <ul className="tabular-data">
                    {version}
                    {description}
                </ul>
                {actionButtons}
                {warnings}
                {errors}
            </>
        );
    }

    formatModuleColumn(moduleName, moduleData) {
        return super.defaultFormatModuleColumn(moduleName, moduleData);
    }
}

export default FutureTab;
