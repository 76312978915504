'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { States } from '@uw-it-sis/lib-react/lib/AppConstants';
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import ReleasesTable from './tables/ReleasesTable';
import TableCellFormatter from './tables/TableCellFormatter';
import LineItem from './tables/LineItem';
import { Case, Default, Switch } from 'react-if';
import ReleasesStore from './ReleasesStore';

class HistoricalTab extends Component {

    static propTypes = {
        releases: PropTypes.object.isRequired,
    }

    render() {
        return (
            /* Render the first <Case> whose condition matches, or <Default>
             *
             * This is set up so that the page doesn't jump around on a RE-load, but shows a spinner on the first load.
             */
            <Loader show={ReleasesStore.state == States.pending}>
                <Switch>

                    <Case condition={ReleasesStore.state == States.initial}>
                        <span className="sr-only">Loading...</span>;
                    </Case>

                    <Case condition={this.props.releases.length == 0}>
                        <div>There are currently no historical releases</div>
                    </Case>

                    <Default>
                        {() => {
                            let formatter = new HistoricalFormatter();
                            return <ReleasesTable
                                data={this.props.releases}
                                formatter={formatter}
                                moduleColumnFormatter={formatter.formatModuleColumn} />
                        }}
                    </Default>
                </Switch>
            </Loader>
        )
    }
}

/*
 * Formatter for cells in the Historical releases table
 */
class HistoricalFormatter extends TableCellFormatter {

    /*
     * Returns the JSX to render for a release info cell, the top row in the table
     */
    formatReleaseInfo(release, row, rowIndex, formatExtraData) {
        const description = this.buildDescriptionLine(release);
        const errors = this.buildErrors(release);
        const warnings = this.buildWarnings(release);

        return (
            <>
                <ul className="tabular-data">
                    <LineItem name="Collective Version:" val={release.productVersion} />
                    <LineItem name="Release Date:" val={release.releaseDate} />
                    {description}
                </ul>
                {warnings}
                {errors}
            </>
        );
    }

    /*
     * Returns the JSX to render for a module version cell
     */
    formatModuleVersion(module, row, rowIndex, formatExtraData) {
        const version = this.buildModuleVersionText(module);
        const description = this.buildDescriptionLine(module);
        const errors = this.buildErrors(module);
        const warnings = this.buildWarnings(module);

        return (
            <>
                <ul className="tabular-data">
                    {version}
                    {description}
                </ul>
                {warnings}
                {errors}
            </>
        );
    }

    formatModuleColumn(moduleName, moduleData) {
        return super.defaultFormatModuleColumn(moduleName, moduleData);
    }
}

export default HistoricalTab;
