'use strict';

import React, { Component } from 'react'
import {observer} from 'mobx-react';

import { observable, configure, action, flow, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import _ from 'underscore';

import ActionButton from '../components/ActionButton';
import {ClawModalContext} from './ClawModalLib';

/*
 * An ActionButton that triggers a popup modal.
 *
 * This is like ActionButton, but takes a child component that is only rendered
 * after the button is pressed. The callback function to close the popup is
 * passed to children through a React Context.
 *
 * By encapsulating modal open/close state in this component, we simplify
 * our popup forms by 1) reducing the state they have to manage and 2)
 * ensuring that forms and their state objects only exist when displayed on the screen.
 */
class ActionPopupButton extends Component {
    constructor(props) {
        super(props);
        this.store = new isModalOpenStore();
    }

    render() {
        let context = {
            closeModal: this.store.closeModal,
        };
        return (
            <>
                <ActionButton
                    action={this.props.action}
                    disabled={this.props.disabled}
                    onClick={this.store.toggleModal} />
                <ClawModalContext.Provider value={context}>
                    {this.store.isOpen ? this.props.children : null}
                </ClawModalContext.Provider>
            </>
        );
    }
}

class isModalOpenStore {
    isOpen = false;

    openModal = () => this.isOpen = true;
    closeModal = () => {
        return this.isOpen = false
    };
    toggleModal = () => this.isOpen = ! this.isOpen;

    constructor() {
        makeObservable(this, {
            isOpen: observable,
            closeModal: action,
            openModal: action,
            toggleModal: action,
        });
    }
}

export default observer(ActionPopupButton);
