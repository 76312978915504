'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';

import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";
import ActionButton from '../../components/ActionButton';

class PromoteReleaseForm extends Component {

    static propTypes = {
        release: PropTypes.object.isRequired
    }

    // Arrow syntax here means we don't have to rebind the function to this
    submit = () => {
        ReleasesStore.promoteRelease(this.props.release);
    }

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
    }

    render() {
        let title;
        if (this.props.release.releaseNamingEnabled) {
            title = `Promote Release ${this.props.release.releaseName} (${this.props.release.productVersion})?`;
        } else {
            title = `Promote Release ${this.props.release.productVersion}?`;
        }

        let message = this._makeMessage(this.props.release);

        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.PROMOTE}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={title}
                    message={message}
                    confirmText="Promote"
                    onConfirm={this.submit}
                    />
            </>
        )
    }

    /**
     * Formats the "are you sure?" message.
     * @param release
     */
    _makeMessage(release) {
        let message = null;

        switch(release.status) {
            case "ACTIVE":
                let releaseText;
                if (release.releaseNamingEnabled) {
                    releaseText = `${release.releaseName} (v${release.productVersion})`
                } else {
                    releaseText = release.productVersion;
                }
                let oldBranch = release.releaseRepositoryMetadata.branch.toString().toLowerCase()
                let newBranch = release.releaseRepositoryMetadata.nextBranch.toString().toLowerCase()
                message = <div>You are about to move the {releaseText} release from the <strong>{oldBranch}</strong> branch to <strong>{newBranch}</strong>. Are you sure?</div>
                break;
            default:
                message = "Invalid release type. You should cancel.";
        }

        return message;
    }
}

export default PromoteReleaseForm;
