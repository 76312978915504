'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';
import ActionButton from '../../components/ActionButton';

/*
 * These aren't even forms really, just buttons that trigger an action.
 */

export class StepPromotionForm extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired
    }

    render() {
        return <ActionButton
            action={ActionUtils.Actions.STEP_PROMOTION}
            onClick={() => ReleasesStore.promoteRelease(this.props.release)}
            />
    }
}

export class MarkModuleBuiltForm extends Component {
    static propTypes = {
        module: PropTypes.object.isRequired,
    }

    render() {
        return <ActionButton
            action={ActionUtils.Actions.MARK_BUILT}
            onClick={() => ReleasesStore.markModuleAsBuilt(this.props.module)}
            />
    }
}

export class MarkModuleFailedForm extends Component {
    static propTypes = {
        module: PropTypes.object.isRequired
    }

    render() {
        return <ActionButton
            action={ActionUtils.Actions.MARK_FAILED}
            onClick={() => ReleasesStore.markModuleAsFailed(this.props.module)}
            />
    }
}
