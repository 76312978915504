'use strict';

import React from 'react';
import classnames from "classnames";

import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {When} from "react-if";

import LineItem from './LineItem';
import Commits from '../Commits';
import ModuleStatus from '../../modules/ModuleStatus';
import {convertGitURL} from "../../utils/utils";

import {FiGithub} from "react-icons/fi";

/*
 * Base class for formatting the cells of a table.
 *
 * The format() function is the main entrypoint function.
 *
 * For this class to be used, it needs to be subclassed with the
 * formatReleaseInfo() and formatModuleVersion() functions implemented.
 *
 * The formatReleaseInfo() function will format cells in the first non-header
 * row that describe the release itself, while the formatModuleVersion() function
 * formats the rest of the cells in the table that describe the module version
 * for a release.
 */
class TableCellFormatter {

    /*
     * This function formats the contents of each cell, and returns the jsx to
     * be displayed.
     *
     * Since the first (non-header) row in the table shows info about the release,
     * and the rest of the rows show info about modules in a release, this function
     * will have to discern which type of cell it's currently formatting.
     *
     * The react table component should be passing module info in as the first
     * arg 'cell', and the info for the current release (the column) can be
     * accessed at 'formatExtraData.release'. The 'row' arg is actually the row
     * object passed to the table with a property for each column.
     *
     * Declaring as arrow syntax, so that when this func gets passed to the
     * table, it stays bound to 'this'
     */
    format = (cell, row, rowIndex, formatExtraData) => {
        if (! cell) {
            return <Alert variant='danger'>Data unavailable</Alert>;
        }
        if (rowIndex === 0) {
            // we're formatting the release info row
            return this.formatReleaseInfo(cell, row, rowIndex, formatExtraData);
        } else {
            return this.formatModuleVersion(cell, row, rowIndex, formatExtraData);
        }
    }

    /*
     * Builds the text for a module version.
     * The version # is grayed out if it wasn't updated in this release.
     */
    buildModuleVersionText(module) {
        let versionText;
        if (module.inRelease) {
            let title = "This module is/was in this release at this version. (The database version).";
            versionText = <span title={title}>{module.version}</span>
        } else {
            let title = "This module isn't/wasn't in this release, but this is the most recent version of the module." ;
            // show n/a if the module didn't even exist for this release
            let version = module.version ? module.version : "n/a";
            versionText = <span title={title} className="text-gray-dark">{version}</span>
        }

        return <LineItem name="Version:" val={versionText} />
    }

    /*
     * show the version of a module as defined by it's git repo
     */
    buildRepoVersionLine(module) {
        if (module.repositoryMetadata) {
            let title = "This is the module version defined in the module's Git repo.";
            let val = <span title={title}>{module.repositoryMetadata.version}</span>;
            return <LineItem name="Repo Version:" val={val} />
        } else {
            return null;
        }
    }

    /*
     * Returns JSX of the description of this item.
     * If there is no description, don't display this line
     */
    buildDescriptionLine(cell) {
        return cell.description ? <LineItem name="Description:" val={cell.description} /> : null;
    }
    /*
     * Returns JSX for displaying errors
     * If there are no errors, don't display anything.
     * @param cell Can either be a release or a module.
     */
    buildErrors(cell) {
        let errorsComponent = null;
        if (cell.errors.length > 0) {
            let errors = cell.errors.map(function (message, index) {
                 return <li key={index}>{message}</li>;
            });
            errorsComponent =
                <div className="mt-3">
                    <Alert variant="danger" className="mb-0 alert-sm d-inline-block">
                        <ul className="list-unstyled">
                            {errors}
                        </ul>
                    </Alert>
                </div>;
        }
        return errorsComponent;
    }

    /*
     * Builds a component for displaying warnings. If there are no warnings displays nothing.
     * @param cell Either a release or a module.
     */
    buildWarnings(cell) {
        let errorsComponent = null;
        if (cell.warnings.length > 0) {
            const warnings = cell.warnings.map(function (message, index) {
                 return <li key={index}>{message}</li>;
            });
            errorsComponent =
                <div className="mt-3">
                    <Alert variant="warning" className="mb-0 alert-sm d-inline-block">
                        <ul className="list-unstyled">
                            {warnings}
                        </ul>
                    </Alert>
                </div>;
        }
        return errorsComponent;
    }

    buildCommitsLine(module) {
        return <Commits module={module} />
    }

    defaultFormatModuleColumn(moduleName, moduleData, options={actions: null}) {
        let moduleStatus = moduleData.status;

        let badgeVariant = null;
        switch (moduleStatus) {
            case ModuleStatus.DEPRECATED: badgeVariant = "danger"; break;
            // "inactive" is a custom badge variant, with css in main.scss
            case ModuleStatus.INACTIVE: badgeVariant = "inactive"; break;
        }

        let gitUrl = moduleData.repositoryMetadata ? convertGitURL(moduleData.repositoryMetadata.url) : undefined;

        return (
            <>
                {/* MODULE NAME */}
                <div className="text-nowrap d-flex align-items-center justify-content-between">
                    <span className={classnames("lead fw-bold me-2",
                        {"text-muted": moduleStatus === ModuleStatus.INACTIVE})}>
                        {moduleName}
                    </span>

                    {/* Github Link */}
                    <When condition={gitUrl}>
                        <Button variant="outline-dark" href={gitUrl} size="sm" target="_blank"
                                title={`GitHub Repo for ${moduleName}`}>
                            <FiGithub aria-hidden={true} />
                        </Button>
                    </When>
                </div>

                {/* STATUS BADGE */}
                <When condition={badgeVariant}>
                    <div>
                        <Badge bg={badgeVariant}>
                            {moduleStatus}
                        </Badge>
                    </div>
                </When>

                {/* ACTIONS */}
                {options.actions}
            </>
        )

    }

}

export default TableCellFormatter;
