'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';
import ActionButton from '../../components/ActionButton';
import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";

class ActivateReleaseForm extends Component {

    static propTypes = {
        release: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
    }

    render() {
        let releaseText;
        if (this.props.release.releaseNamingEnabled) {
            releaseText = `${this.props.release.releaseName} (${this.props.release.productVersion})?`;
        } else {
            releaseText = this.props.release.productVersion
        }

        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.MAKE_ACTIVE}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={`Activate Release ${releaseText} ?`}
                    message={`You are about to move the ${releaseText} release to the development branch. Are you sure?`}
                    confirmText="Activate"
                    onConfirm={() => ReleasesStore.promoteRelease(this.props.release)}
                    style="warning" />
            </>
        )
    }
}

export default ActivateReleaseForm;
