'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';
import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";
import ActionButton from '../../components/ActionButton';

class DeleteReleaseForm extends Component {

    static propTypes = {
        release: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
    }

    render() {
        let releaseText;
        if (this.props.release.releaseNamingEnabled) {
            releaseText = `${this.props.release.releaseName} (v${his.props.release.productVersion}) ?`;
        } else {
            releaseText = this.props.release.productVersion;
        }

        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.DELETE_RELEASE}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={`Delete Release ${releaseText}?`}
                    message="You'll have to manually rebuild this release if you delete it. Are you sure?"
                    confirmText="Delete"
                    onConfirm={() => ReleasesStore.deleteRelease(this.props.release)} />
            </>
        )

    }
}

export default DeleteReleaseForm;
