'use strict';

// Pull in the styles ...
import './main/scss/main.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import {AppContext} from "@uw-it-sis/lib-react/lib/AppContext";
import App from "@uw-it-sis/lib-react/lib/App";
import history from '@uw-it-sis/lib-react/lib/History';
import AppHeaderDefault from "@uw-it-sis/lib-react/lib/AppHeaderDefault";

import TheClawApp from "./main/js/app/TheClawApp";

/**
 * Define the routes.
 */
const routes =
    <Routes>
        <Route path="" element={<TheClawApp />}>
            <Route path=":blocCode" element={<TheClawApp />}>
                <Route path=":collectiveCode" element={<TheClawApp />}>
                    <Route path=":page" element={<TheClawApp />} />
                </Route>
            </Route>
        </Route>
    </Routes>;

let context = {
    appStore: appStore,
    history: history
}

/**
 * Put the AppStore in the React context so that it's available to any class that needs it.
 */
const router = (
    <AppContext.Provider value={context}>
        <HashRouter>
            <App
                name={"Claw"}
                title="The Claw"
                showAppNavigation={false}
                header={<AppHeaderDefault title="The Claw" enableBannerMessages={false} />}
                routes={routes}/>
        </HashRouter>
    </AppContext.Provider>
);

const appContainer = document.getElementById('react_app');
const root = createRoot(appContainer);
root.render(router);