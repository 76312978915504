'use strict';

import { flow, observable, configure, action, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import theclaw from '../utils/Api';
import ModuleStatus from './ModuleStatus';

class ModulesStore {

    state = States.initial;
    includeInactive = false;

    constructor() {
        makeObservable(this, {
            state: observable,
            includeInactive: observable,
            toggleIncludeInactive: action
        });

        this.modules = [];
        this.toggleIncludeInactive = this.toggleIncludeInactive.bind(this);
    }

    getModules = () => {
        if (this.includeInactive) {
            return this.modules;
        } else {
            return this.modules.filter(module => module.status !== ModuleStatus.INACTIVE);
        }
    };

    loadModules = flow(function* (blocCode, collectiveCode) {
        this.state = States.pending;
        yield new Promise(r => setTimeout(r, 2000));  // TODO DELETE ME
        this.blocCode = blocCode;
        this.collectiveCode = collectiveCode;
        try {
            this.modules = yield theclaw.listModules(blocCode, collectiveCode);
            this.state = States.done;
        } catch (e) {
            this.state = States.error;
            console.log(e);
        }
    }.bind(this));

    updateModuleStatus = flow(function* (module) {
        this.state = States.pending;
        try {
            yield theclaw.updateModule(this.blocCode, this.collectiveCode, module);
            this.state = States.done;
            // Rather than reload the module data just update the status on the module object.
            this.modules.forEach(function (m) {
                if (module.id === m.id) {
                    m.status = module.status;
                }
            }, this);
        } catch (e) {
            this.state = States.error;
            console.log(e);
        }
    }.bind(this));

    toggleIncludeInactive() {
        this.includeInactive = !this.includeInactive;
    }
}

export default new ModulesStore();
