'use strict';
/*
 * Converts a github url in ssh format to the https url of that repo
 * Returns null if the url can't be converted.
 *
 * e.g. convert
 *    git@github.com:uw-it-sis/app-theclaw
 * to
 *    https://github.com/uw-it-sis/app-theclaw
 */
export function convertGitURL(url) {
    let match = url.match(/(?:ssh:\/\/)?git@github.com:\/?(.*?)(?:\.git)?$/);

    if (match && match.length > 1) {
        return `https://github.com/${match[1]}`
    }

    // Try again with our sandbox url that's used in dev
    match = url.match(/(?:ssh:\/\/)?git@repo-sandbox.theclaw-dev.local.net\/git-server\/repos\/?(.*?)(?:\.git)?$/);
    if (match && match.length > 1) {
        return `https://github.com/uw-it-sis/${match[1]}`
    }

    return null;
}

/*
 * Returns the url for the github actions page for this repo
 */
export function getGithubActionsURL(sshUrl) {
    let githubUrl = convertGitURL(sshUrl);
    if (githubUrl) {
        return `${githubUrl}/actions`;
    } else {
        return null;
    }
}

export function isValidVersion(v) {
    if (! v) {
        return false;
    }
    let match = v.match(/^v?\d+\.\d+\.(\d+|x|X)(\.\d+|-SNAPSHOT)?$/)
    return !! match
}
