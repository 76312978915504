'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import AddUpdateModuleFormStore from '../AddUpdateModuleFormStore';
import ActionUtils from '../../utils/ActionUtils';
import ActionButton from '../../components/ActionButton';
import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";

class RemoveModuleForm extends Component {

    static propTypes = {
        release: PropTypes.object.isRequired,
        module: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
        this.addUpdateFormStore = new AddUpdateModuleFormStore();
    }

    render() {
        let releaseName;
        if (this.props.release.releaseNamingEnabled) {
            releaseName = this.props.release.releaseName
        } else {
            releaseName = this.props.release.productVersion;
        }

        let moduleName = this.props.module.name

        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.REMOVE_MODULE}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={`Remove Module '${moduleName}' from release '${releaseName}'?`}
                    message="Are you sure you want to remove this module from the release?"
                    confirmText="Remove"
                    onConfirm={() => this.addUpdateFormStore.removeModule(this.props.module, this.props.release)}
                    style="warning"
                    />
            </>
        )
    }
}

export default RemoveModuleForm;
