'use strict';

import { flow, action, observable, configure, observe, computed, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {mapBlocs} from "../utils/mapBlocs";
import {values, keys} from 'lodash';

import theclaw from '../utils/Api';

/*
 * Global data store for the entire The Claw app
 */
class TheClawStore {

    state = States.initial;

    // The currently selected Bloc object
    currentBloc;

    // The currently selected Collective object.
    currentCollective;

    // Collective tab to display (modules, releases, log).
    collectiveTab = "releases";

    constructor() {
        makeObservable(this, {
            state: observable,
            currentBloc: observable,
            currentCollective: observable,
            collectiveTab: observable,
            setCurrentBloc: action,
            setCurrentCollective: action,
            setCollectiveTab: action
        });

        // This is a map of blocs
        this.blocs = {};
    }

    // 'releases', 'modules', 'log'
    setCollectiveTab = (tab) => this.collectiveTab = tab;

    getCollectiveTab = () => {
        return this.collectiveTab
    }

    /**
     * Sets the current bloc object.
     * @param blocCode The code of the current bloc.
     */
    setCurrentBloc = (blocCode) => {
        this.currentBloc = this.blocs[blocCode].bloc;
        this.currentCollective = null;
        // Set the current collective to the first collective if it exists ...
        if (keys(this.blocs[blocCode].collectives).length > 0) {
            const firstKey = keys(this.blocs[blocCode].collectives)[0];
            this.setCurrentCollective(this.blocs[blocCode].collectives[firstKey]);
        }
    };

    /**
     * Get the current bloc object.
     */
    getCurrentBloc = () => {
        return this.currentBloc;
    };

    /**
     * Set the current collective.
     * @param collective Either a collective object or the collective code.
     */
    setCurrentCollective = (collective) => {
        if (typeof collective === "string") {
            const blocCode = this.getCurrentBloc().code;
            this.currentCollective = this.blocs[blocCode].collectives[collective];
        } else {
            this.currentCollective = collective;
        }
    };

    /**
     * Gets the current collective object.
     */
    getCurrentCollective = () => {
       return this.currentCollective;
    }

    /**
     * Gets the collective objects for the given Bloc code as an array.
     */
    getCollectives = (blocCode) => {
        return values(this.blocs[blocCode].collectives);
    }

    /**
     * Gets the list of Bloc objects as an array.
     */
    getBlocs = () => {
        return values(this.blocs).map((b) => {
            return b.bloc;
        });
    }

    /**
     * Load Blocs.
     * @param blocCode If present, the bloc to set as current. Otherwise, ignored.
     * @param collectiveCode If present, and bloc is set, the collective to set as the current. Otherwise, ignored.
     */
    loadBlocs = flow(function* (blocCode, collectiveCode) {
        this.state = States.pending;
        try {
            this.blocs = mapBlocs(yield theclaw.listBlocs());
            // If a bloc code was provided then go ahead and set the current bloc to it.
            if (blocCode && this.blocs[blocCode]) {
                this.setCurrentBloc(this.blocs[blocCode].bloc.code);
                if (collectiveCode) {
                    this.setCurrentCollective(this.blocs[blocCode].collectives[collectiveCode]);
                }
            } else {
                // Otherwise, just set the current bloc to the bloc, alphabetically
                this.setCurrentBloc(keys(this.blocs).sort()[0]);
            }
            this.state = States.done;
        } catch(error) {
            this.state = States.error;
        }
    }.bind(this));
}

export default new TheClawStore();
