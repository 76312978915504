'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import Form from 'react-bootstrap/Form';

import AddUpdateModuleFormStore from '../AddUpdateModuleFormStore';
import {isValidVersion} from '../../utils/utils';
import { ANY_VERSION } from '../../utils/Constants';

import {
    ClawModal,
    ClawModalHeader,
    ClawModalBody,
    ClawModalFooter,
    ClawForm,
} from '../../components/ClawModalLib';

/*
 * The actual form of the popup to add or update a module to a release.
 */
class AddUpdateModuleForm extends Component {
    static propTypes = {
        mode: PropTypes.string.isRequired,
        module: PropTypes.object.isRequired,
        release: PropTypes.object.isRequired,
        collective: PropTypes.object.isRequired,
        blocCode: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.store = new AddUpdateModuleFormStore();
    }

    componentDidMount() {
        this.store.mount(
            this.props.mode,
            this.props.module,
            this.props.release,
            this.props.collective,
            this.props.blocCode
        );
    }

    handleFormChange = (e) => {
        let property = e.target.name;
        let value = e.target.value
        this.store.updateFormData(property, value);
    }

    _buildModalBody() {
        let versionEntry;
        if (! this.store.versionOptions.includes(ANY_VERSION)) {
            // Default drop-down picker
            versionEntry =
                <Form.Select
                    value={this.store.formData.version}
                    name="version"
                    onChange={this.handleFormChange}>
                    {
                        this.store.versionOptions.map((version, i) =>
                            <option key={i} value={version}>{version}</option>
                        )
                    }
                </Form.Select>
        } else {
            // If the x.x.x version was found, that means this is a new
            // module without a previous version, and we should allow the user
            // to write in whatever they need.
            let majMinPatch = "<major>.<minor>.<patch>"
            let majMinX = "<major>.<minor>.x"
            versionEntry =
                <>
                    <Form.Control
                        name="version"
                        type="text"
                        value={this.store.formData.version}
                        isInvalid={!isValidVersion(this.store.formData.version)}
                        onChange={this.handleFormChange}
                    />
                    <Form.Control.Feedback type="invalid" aria-live="polite">
                        Not a valid version format.
                    </Form.Control.Feedback>
                    <Form.Text>
                        This module has not been released before. Enter a version of the form "{majMinPatch}".
                        <p>If this is an npm library module where the patch version often changes, use the form "{majMinX}"</p>
                    </Form.Text>
                </>
        }

        return (
            <ClawForm
                onSubmit={this.store.submit}
                state={this.store.state}>

                <Form.Group controlId="version">
                    <Form.Label>Version</Form.Label>
                    {versionEntry}
                </Form.Group>

                <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        name="description"
                        type="text"
                        value={this.store.formData.description}
                        onChange={this.handleFormChange}
                    />
                    <Form.Text>A summary of why this module is included in the release.</Form.Text>
                </Form.Group>
            </ClawForm>
        );
    }

    render() {
        let modalTitle;
        switch(this.props.mode) {
            case "add": modalTitle = `Add Module '${this.props.module.name}' to release`; break;
            case "update": modalTitle = `Update Module '${this.props.module.name}'`; break;
        }

        return (
            <ClawModal id="AddUpdateModuleModal">
                <ClawModalHeader title={modalTitle}/>
                <ClawModalBody>
                    {this._buildModalBody()}
                </ClawModalBody>
                <ClawModalFooter 
                    onSubmit={this.store.submit}
                    loadingState={this.store.state}
                    />
            </ClawModal>
        );
    }
}

export default observer(AddUpdateModuleForm);
