'use strict';

import React from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {
    FaPencilAlt, FaPlus, FaBackward, FaForward, FaRedoAlt, FaBomb, FaRegTrashAlt, FaRegPaperPlane, FaRegThumbsDown,
    FaRegThumbsUp, FaQuestionCircle, FaPlay, FaAngleDown, FaAngleDoubleDown
} from "react-icons/fa";
import {
    GiExplodingPlanet, GiPlanetConquest
} from "react-icons/gi";

import ActionPopupButton from '../components/ActionPopupButton';
import DeleteReleaseForm from '../releases/forms/DeleteReleaseForm';
import PromoteReleaseForm from '../releases/forms/PromoteReleaseForm';
import ActivateReleaseForm from '../releases/forms/ActivateReleaseForm';
import RemoveModuleForm from '../releases/forms/RemoveModuleForm';
import AddUpdateModuleForm from '../releases/forms/AddUpdateModuleForm';
import ReleaseForm from '../releases/forms/ReleaseForm';
import {
    MarkModuleBuiltForm,
    MarkModuleFailedForm,
    StepPromotionForm,
} from '../releases/forms/PromotionForms';

/**
 * Utility methods for Action related stuff.
 */
let ActionUtils = {};

/**
 * These actions align with the Actions enum on the backend.
 */
ActionUtils.Actions = {
    NEW_BLOC: {
        label: "Add Bloc",
        title: "Create a new Bloc",
        glyph: FaPlus,
        haspopup: "dialog"
    },
    UPDATE_BLOC: {
        label: "Update",
        title: "Update this Bloc",
        glyph: FaPencilAlt,
        haspopup: "dialog"
    },
    NEW_COLLECTIVE: {
        label: "Add Collective",
        title: "Add a new Collective to this Bloc",
        glyph: FaPlus,
        haspopup: "dialog"
    },
    UPDATE_COLLECTIVE: {
        label: "Update",
        title: "Update this Collective",
        glyph: FaPencilAlt,
        haspopup: "dialog"
    },
    NEW_RELEASE: {
        label: "New",
        title: "Create a new Release",
        glyph: FaPlus,
        haspopup: "dialog"
    },
    NEW_PATCH_RELEASE: {
        label: "Patch",
        title: "Create a new patch Release",
        glyph: FaBackward,
        haspopup: "dialog"
    },
    DELETE_RELEASE:  {
        label: "Delete",
        title: "Delete this Release",
        glyph: FaRegTrashAlt,
        haspopup: "dialog"
    },
    ADD_TO_RELEASE: {
        label: "Add",
        title: "Add module to this Release",
        glyph: FaPlus,
        haspopup: "dialog"
    },
    UPDATE_RELEASE: {
        label: "Update",
        title: "Update this Release",
        glyph: FaPencilAlt,
        haspopup: "dialog"
    },
    UPDATE_MODULE: {
        label: "Update",
        title: "Update this Module",
        glyph: FaPencilAlt,
        haspopup: "dialog"
    },
    REMOVE_MODULE: {
        label: "Remove",
        title: "Remove this module",
        glyph: FaRegTrashAlt,
        haspopup: "dialog"
    },
    REMOVE_FROM_RELEASE: {
        label: "Remove",
        title: "Remove module from this Release",
        glyph: FaRegTrashAlt,
        haspopup: "dialog"
    },
    PROMOTE: {
        label: "Promote",
        title: "Merge all code to the next branch.",
        glyph: FaForward,
        haspopup: "dialog"
    },
    MAKE_ACTIVE: {
        label: "Activate",
        title: "Make this future release active by moving it into source control",
        glyph: FaForward,
        haspopup: "dialog"
    },
    RELEASE: {
        label: "Release",
        title: "Merge to master and tag",
        glyph: FaRegPaperPlane,
        haspopup: "dialog"
    },
    REFRESH_REPO: {
        label: "Pull",
        title: "Pull changes from upstream",
        glyph: FaAngleDown,
        haspopup: false
    },
    REFRESH_ALL_REPOS: {
        label: "Pull All",
        title: "Pull changes from upstream for all repos",
        glyph: FaAngleDoubleDown,
        haspopup: false
    },
    RESET_REPO: {
        label: "Re-clone",
        title: "Destroy the working copy of this repo and re-clone",
        glyph: FaBomb,
        haspopup: "dialog"
    },
    RESET_ALL_REPOS: {
        label: "Re-clone all",
        title: "Destroy working copy of all repos and re-clone",
        glyph: FaBomb,
        haspopup: "dialog"
    },
    EDIT_MODULE: {
        label: "Edit",
        title: "Edit this Module",
        glyph: FaPencilAlt,
        haspopup: "dialog"
    },
    NEW_MODULE: {
        label: "New",
        title: "Create a new Module",
        glyph: FaPlus,
        haspopup: "dialog"
    },
    MODULE_ACTIVATE: {
        label: "Activate",
        title: "Activate this Module",
        glyph: FaRegThumbsUp,
        haspopup: false
    },
    MODULE_DEPRECATE: {
        label: "Deprecate",
        title: "Deprecate this Module",
        glyph: FaRegThumbsDown,
        haspopup: false
    },
    MARK_BUILT: {
        label: "Mark Built",
        title: "Manually mark this module as having been successfully built. The next promotion wave will be launched if this is the last module in this wave.",
        glyph: (props) => <GiPlanetConquest {...props} style={{height:"2em",width:"2em"}}/>,
        haspopup: false
    },
    MARK_FAILED: {
        label: "Mark Failed",
        title: "Manually mark this module as having failed its build. It's unlikely this will be needed in production, but the option is here.",
        glyph: (props) => <GiExplodingPlanet {...props} style={{height:"1.5em",width:"1.5em"}}/>,
        haspopup: false
    },
    STEP_PROMOTION: {
        label: "Step Promotion",
        title: "Use this to attempt to step the promotion forward if things seem to have gotten stuck between waves. The Claw will kick off the next wave if it is appropriate to do so.",
        glyph: FaPlay,
        haspopup: false,
    },
    // Create a placeholder if a new or invalid action name was found
    __DEFAULT__: function(actionName) {
        return {
            label: actionName,
            title: actionName,
            glyph: FaQuestionCircle,
            haspopup: false,
        }
    }

};

function makeNoActionDefinedAction(name) {
    return function noActionDefined() {
        alert(`Action ${name} is not defined`);
    }
}

/**
 * Given a Release, return a list of appropriate actionable popover buttons
 */
ActionUtils.buildReleaseActionButtons = function(release, collective, blocCode) {

    let releaseInfo = {
        release,
        blocCode,
        collective,
    };

    // Make a list of actions for the release.
    let actions = release.availableActions.map(function(action, index) {
        switch(ActionUtils.Actions[action]) {
            case ActionUtils.Actions.DELETE_RELEASE:
                return <DeleteReleaseForm key={index} {...releaseInfo} />;
            case ActionUtils.Actions.UPDATE_RELEASE:
                return (
                    <ActionPopupButton key={index} action={action}>
                        <ReleaseForm mode="update" key={index} {...releaseInfo} />
                    </ActionPopupButton>
                );
            case ActionUtils.Actions.NEW_PATCH_RELEASE:
                return (
                    <ActionPopupButton key={index} action={action}>
                        <ReleaseForm mode="patch" key={index} {...releaseInfo} />
                    </ActionPopupButton>
                );
            case ActionUtils.Actions.PROMOTE:
                return <PromoteReleaseForm key={index} {...releaseInfo} />;
            case ActionUtils.Actions.MAKE_ACTIVE:
                return <ActivateReleaseForm key={index} {...releaseInfo} />;
            case ActionUtils.Actions.STEP_PROMOTION:
                return <StepPromotionForm key={index} {...releaseInfo} />;
            default:
                // Should never have gotten here - Make a button that doesn't do anything.
                return <ActionButton
                    action={action}
                    onClick={makeNoActionDefinedAction(action)}
                    key={index}
                    />
        }
    });

    if (actions.length) {
        return (
            <ButtonGroup className="btn-group-vertical-sm">
                {actions}
            </ButtonGroup>
        );
    } else {
        return null;
    }
}

/**
 * Given a Module, return a list of actionable popover buttons
 */
ActionUtils.buildModuleActionButtons = function(module, release, collective, blocCode) {
    let moduleInfo = {
        module,
        release,
        collective,
        blocCode,
    };

    // Make a list of actions for the module.
    let actions = module.availableActions.map(function(action, index) {

        switch(ActionUtils.Actions[action]) {
            case ActionUtils.Actions.ADD_TO_RELEASE:
                return (
                    <ActionPopupButton key={index} action={action}>
                        <AddUpdateModuleForm mode="add" {...moduleInfo} />
                    </ActionPopupButton>
                );
            case ActionUtils.Actions.UPDATE_MODULE:
                return (
                    <ActionPopupButton key={index} action={action}>
                        <AddUpdateModuleForm mode="update" {...moduleInfo} />
                    </ActionPopupButton>
                );
            case ActionUtils.Actions.REMOVE_FROM_RELEASE:
                return <RemoveModuleForm mode="update" {...moduleInfo} key={index} />;
            case ActionUtils.Actions.MARK_BUILT:
                return <MarkModuleBuiltForm {...moduleInfo} key={index} />;
            case ActionUtils.Actions.MARK_FAILED:
                return <MarkModuleFailedForm {...moduleInfo} key={index} />;
            default:
                // Should never have gotten here - Make a button that doesn't do anything.
                return <ActionButton
                    action={action}
                    onClick={makeNoActionDefinedAction(action)}
                    key={index}
                    />
        }
    });

    if (actions.length) {
        return (
            <ButtonGroup className="btn-group-vertical-sm">
                {actions}
            </ButtonGroup>
        );
    } else {
        return null;
    }
}

export default ActionUtils;