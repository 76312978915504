'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Badge from "react-bootstrap/Badge";
import {When, Case, Switch, Default} from "react-if";

import { States } from '@uw-it-sis/lib-react/lib/AppConstants';
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import ReleasesTable from './tables/ReleasesTable';
import TableCellFormatter from './tables/TableCellFormatter';
import LineItem from './tables/LineItem';
import ActionUtils from '../utils/ActionUtils';
import ActionButton from '../components/ActionButton';
import RepoState from '../utils/RepoState';
import ResetForm from './forms/ResetForm';
import ResetAllForm from './forms/ResetAllForm';
import ReleasesStore from "./ReleasesStore";
import {getGithubActionsURL} from "../utils/utils";

import {FaCheck, FaTimes} from "react-icons/fa";
import {BsGearWideConnected as Gear} from "react-icons/bs";

class ActiveTab extends Component {

    static propTypes = {
        collective: PropTypes.object.isRequired,
        blocCode: PropTypes.string.isRequired,
        releases: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
    }

    render() {
        let formatter = new ActiveFormatter(this.props.collective, this.props.blocCode);

        return (
            /* Render the first <Case> whose condition matches, or <Default>
             *
             * This is set up so that the page doesn't jump around on a RE-load, but shows a spinner on the first load.
             */
            <Loader show={ReleasesStore.state == States.pending}>
                <Switch>

                    <Case condition={ReleasesStore.state == States.initial}>
                        <span className="sr-only">Loading...</span>;
                    </Case>

                    <Case condition={this.props.releases.releases.length == 0}>
                        <div>There are currently no active releases</div>
                    </Case>

                    {/* Render this block if none of the previous conditions matched */}
                    <Default>
                            <ButtonGroup className="mb-3">
                                <ActionButton
                                    action={ActionUtils.Actions.REFRESH_ALL_REPOS}
                                    onClick={() => ReleasesStore.refreshAllRepos()}
                                />
                                <ResetAllForm />
                            </ButtonGroup>
                            <ReleasesTable
                                data={this.props.releases}
                                formatter={formatter}
                                moduleColumnFormatter={formatter.formatModuleColumn} />
                    </Default>
                </Switch>
            </Loader>
        );
    }
}

/*
 * Formatter for cells in the Active releases table
 */
class ActiveFormatter extends TableCellFormatter {

    constructor(collective, blocCode) {
        super();
        this.blocCode = blocCode;
        this.collective = collective;
    }

    /*
     * Returns the JSX to render for a release info cell, the top row in the table
     */
    formatReleaseInfo(release, row, rowIndex, formatExtraData) {
        const description = this.buildDescriptionLine(release);
        const actionButtons = ActionUtils.buildReleaseActionButtons(release, this.collective, this.blocCode);
        const errors = this.buildErrors(release);
        const warnings = this.buildWarnings(release);
        const promotionInfo = this.buildReleasePromotionComponent(release);

        return (
            <>
                <ul className="tabular-data">
                    <LineItem name="Collective Version:" val={release.productVersion} />
                    <LineItem name="Release Date:" val={release.releaseDate} />
                    <LineItem name="Branch:" val={release.releaseRepositoryMetadata.branch} />
                    {description}
                </ul>
                {actionButtons}
                {promotionInfo}
                {warnings}
                {errors}
            </>
        );
    }

    /*
     * Returns the JSX to render for a module version cell
     */
    formatModuleVersion(module, row, rowIndex, formatExtraData) {
        const version = this.buildModuleVersionText(module);
        const errors = this.buildErrors(module);
        const warnings = this.buildWarnings(module);
        const commits = this.buildCommitsLine(module);
        const repoStatus = this.buildRepoStatusLine(module);
        const actionButtons = ActionUtils.buildModuleActionButtons(module, formatExtraData.release, this.collective, this.blocCode);

        if (module.inRelease) {
            const description = this.buildDescriptionLine(module);
            const repoVersion = this.buildRepoVersionLine(module);
            const promotion = this.buildModulePromotionComponent(module);

            return (
                <>
                    <ul className="tabular-data">
                        {version}
                        {repoVersion}
                        {description}
                        {commits}
                        {repoStatus}
                    </ul>
                    {actionButtons}
                    {promotion}
                    {warnings}
                    {errors}
                </>
            );
        } else {
            return (
                <>
                    <ul className="tabular-data">
                        {version}
                        {commits}
                        {repoStatus}
                    </ul>
                    {actionButtons}
                    {warnings}
                    {errors}
                </>
            );
        }
    }

    buildReleasePromotionComponent(release) {
        const promotion = release.releasePromotionMetadata;
        const modulesCount = promotion?.countModulesTotal;
        return (
            <When condition={promotion.isPromoting}>
                <Alert variant='info'>
                    <p>ONGOING PROMOTION</p>
                    <p>Promoting from {promotion.fromBranch} to {promotion.toBranch}</p>
                    <p>Currently promoting wave {promotion.currentWave}/{promotion.totalWaves}</p>
                    <ul>
                        <li>{promotion.countModulesTotal} modules in release:</li>
                        <li>{promotion.countModulesPromoted}/{modulesCount} promoted</li>
                        <li>{promotion.countModulesBuilding}/{modulesCount} actively building</li>
                        <li>{promotion.countModulesAwaitingPromotion}/{modulesCount} awaiting promotion</li>
                    </ul>
                </Alert>
                <When condition={promotion.countModulesFailed != 0}>
                    <Alert variant='danger'>
                        <p>Action required:</p>
                        <p>{promotion.countModulesFailed} module(s) have build failures</p>
                    </Alert>
                </When>
            </When>
        )
    }

    buildModulePromotionComponent(module) {
        let ghActionsUrl = getGithubActionsURL(module.repositoryMetadata.url);
        switch (module.promotionState) {
            case 'AWAITING_PROMOTION':
                return (
                    <Alert variant="warning">
                        <div className="fw-bold">Awaiting Promotion... </div>
                        <div className="fw-light">(wave {module.promotionWaveNum})</div>
                    </Alert>
                )

            case 'BUILDING':
                let gearR = <Gear aria-hidden={true} className="gearRight" />
                let gearL = <Gear aria-hidden={true} className="gearLeft" />
                return (
                    <div>
                        <Alert variant="info">
                            <div className="fw-bold">
                                Building {gearR}{gearL}{gearR}
                            </div>
                            <div className="fw-light">(wave {module.promotionWaveNum})</div>
                            <When condition={ghActionsUrl != null}>
                                <a href={ghActionsUrl} className="fw-light">View GitHub Actions</a>
                            </When>
                        </Alert>
                    </div>
                );

            case 'BUILD_FAILED':
                let errorSymbol = <FaTimes aria-hidden={true} className="me-2 errorSymbol" />
                return (
                    <div>
                        <Alert variant="danger">
                            <div className="fw-bold">
                                Build Failed {errorSymbol}
                            </div>
                            <When condition={ghActionsUrl != null}>
                                <a href={ghActionsUrl} className="fw-normal">View GitHub Actions</a>
                            </When>
                        </Alert>
                    </div>
                );

            case 'PROMOTED':
                let check = <FaCheck aria-hidden={true} className="mb-1 centerIcon" />
                return <Alert variant="success" className="fw-bold">Promoted {check}</Alert>

            case null:
                return null;

            default:
                // There shouldn't be any other states
                return <Alert>Error unexpected state: {module.promotionState}</Alert>
        }
    }

    /*
     * Show the status of the git repo if it isn't idle
     */
    buildRepoStatusLine(module) {
        // If the repo state isn't idle then show the state.
        if (module.repositoryMetadata && module.repositoryMetadata.state !== RepoState.IDLE) {
            let variant = "warning"
            if (module.repositoryMetadata.state === RepoState.BROKEN) {
                variant = "danger"
            }
            let val = <Badge bg={variant} title={`Git Repo Status ${module.repositoryMetadata.state}`}>{module.repositoryMetadata.state}</Badge>;
            return <LineItem name="Repo Status:" val={val} />
        } else {
            return null
        }
    }

    /*
     * This function will format the far-left module "header" column
     */
    formatModuleColumn(moduleName, moduleData) {
        let actions = (
            <ButtonGroup className="mt-3 btn-group-vertical-sm">
                <ActionButton
                    action={ActionUtils.Actions.REFRESH_REPO}
                    onClick={() => ReleasesStore.refreshModuleRepo(moduleName)} />
                <ResetForm module={moduleName} />
            </ButtonGroup>
        )

        return super.defaultFormatModuleColumn(
            moduleName,
            moduleData,
            {actions: actions}
        );
    }
}

export default ActiveTab;
