'use strict';

import { flow, action, observable, configure, makeObservable } from "mobx";
configure({enforceActions: 'always'});
import {values, find} from 'lodash';

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import exceptionUtils from '@uw-it-sis/lib-react/lib/ExceptionUtils';

import theclaw from '../utils/Api';
import ModuleStatus from '../modules/ModuleStatus';

class ReleasesStore {
    /*
     * Release status.
     */
    ReleaseType = {
        FUTURE: "FUTURE",
        ACTIVE: "ACTIVE",
        PATCH: "PATCH",
        HISTORICAL: "HISTORICAL"
    }
    
    AutoRefreshItems = [
        {label: "10s", milliseconds:  10000},
        {label: "15s", milliseconds:  15000},
        {label: "30s", milliseconds:  30000},
        {label: "45s", milliseconds:  45000},
        {label: "1m",  milliseconds:  60000},
        {label: "2m",  milliseconds: 120000},
        {label: "5m",  milliseconds: 300000},
        {label: "10m", milliseconds: 600000},
    ];

    state = States.initial;
    busyState = States.initial;
    includeInactive = false;
    releases = [];
    tab = "active";

    // An identifier for stopping auto-refresh
    autoRefreshIntervalId;
    autoRefreshEnabled = false;

    constructor() {
        makeObservable(this, {
            state: observable, // The overall state of the component
            busyState: observable, // Used to indicate auto-refresh is refreshing ...
            includeInactive: observable,
            autoRefreshEnabled: observable,
            tab: observable,
            releases: observable,
            setTab: action,
            setState: action,
            toggleIncludeInactive: action,
            stopAutoRefresh: action,
            setAutoRefresh: action,
            _makeModulesList: action,
            refreshModuleRepo: action,
            refreshAllRepos: action,
            resetModuleRepo: action,
            resetAllRepos: action,
            promoteRelease: action,
            deleteRelease: action
        });

        this.blocCode;
        this.collective;
        this.toggleIncludeInactive = this.toggleIncludeInactive.bind(this);
        this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this);
    }

    setTab = (t) => this.tab = t;

    getHistoricalReleases() {
        return this._getReleases("HISTORICAL");
    }

    getActiveReleases() {
        return this._getReleases("ACTIVE");
    }

    getFutureReleases() {
        return this._getReleases("FUTURE");
    }

    setState(state) {
        this.state = state;
    }

    getModules = () => this.modules;

    setStatePending = () => this.setState(States.pending);

    /**
     * Initialize the store.
     * @param collective The current collective;
     */
    mount(blocCode, collective) {
        this.collective = collective;
        this.blocCode = blocCode;
        this.loadReleases(true);
    }

    /**
     * @param block A flag indicating that the UI should be blocked while the operation takes place.
     */
    loadReleases = flow(function* (block) {
        let currentlyLoadingCollective = this.collective.code;

        if (block) {
            this.state = States.pending;
        } else {
            this.busyState = States.pending;
        }

        try {
            let releases = yield theclaw.listReleases(this.blocCode, this.collective.code, true);
            // There's a race condition here... if you quickly click a large collective like Planning then a small one
            // like uw-util, you'll be on the uw-util tab whose data loads quickly but then the large collective's data
            // will load and overwrite it. End result - the displayed releases are for the wrong collective.
            //
            // This check makes sure to only set the fetched releases if they match the displayed tab.
            if (currentlyLoadingCollective === this.collective.code) {
                this.releases = releases;
                this.state = States.done;
                this.busyState = States.done;
            }
        } catch (error) {
            exceptionUtils.handleException(error);
            this.state = States.error;
            this.busyState = States.done;
        }
    }.bind(this));

    toggleIncludeInactive() {
        this.includeInactive = !this.includeInactive;
    }

    /**
     * Returns a set of releases and adjusted module data
     *
     * This returns the data in two formats because the table needs to know
     * info about each release to build the header row, but it also needs the
     * data grouped by module to build each module row.
     */
    _getReleases(statusFilter) {
        let releases = this.releases.filter(r => r.status === statusFilter);
        let modules = this._makeModulesList(releases);

        return {
            releases: releases,
            modules: modules
        }
    }

    /**
     * Given a list of releases, creates a list of module objects, where each
     * module has a reference to each release it's in. Each module should have
     * a property corresponding to each release.
     */
    _makeModulesList = function(releases) {
        let modules = {};

        for (const r in releases) {
            const release = releases[r];
            for (const m in release.modules) {
                let module = release.modules[m];
                if (! this.includeInactive && module.status === ModuleStatus.INACTIVE) {
                  // Skip it
                } else {
                    let name = module.name;
                    // if modules doesn't have a key, initialize the object. Store module with a predictable name.
                    if (! modules[name]) {
                        modules[name] = {
                            'module': name,
                            'moduleObj': module
                        };
                    }
                    modules[name][release.productVersion] = module;
                }
            }
        }

        // Now we've got a map of "module name" to "module obj", where each
        // "module obj" has a key for each release it features in.
        // Need to convert to an array of objects

        let moduleList = values(modules);
        return moduleList;
    };

    async refreshModuleRepo(name) {
        this.state = States.pending;
        try {
            await theclaw.refreshRepo(this.blocCode, this.collective.code, name);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
            this.state = States.error;
        }
    }

    async refreshAllRepos() {
        this.state = States.pending;
        try {
            await theclaw.refreshAllRepos(this.blocCode, this.collective.code);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
            this.state = States.error;
        }
    }

    async resetModuleRepo(name) {
        this.state = States.pending;
        try {
            await theclaw.resetRepo(this.blocCode, this.collective.code, name);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
            this.state = States.error;
        }
    }

    async resetAllRepos() {
        this.state = States.pending;
        try {
            await theclaw.resetAllRepos(this.blocCode, this.collective.code);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
            this.state = States.error;
        }
    }

    /**
     * Promote a release to the next branch
     * @param release The release model.
     */
    async promoteRelease(release) {
        this.state = States.pending;
        try {
            await theclaw.promoteRelease(this.blocCode, this.collective.code, release.entityId);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
            this.state = States.error;
        }
    }

    /**
     * Delete a release. Only valid for future releases
     * @param release The release model.
     */
    async deleteRelease(release) {
        this.state = States.pending;
        try {
            await theclaw.deleteRelease(this.blocCode, this.collective.code, release.entityId);
            this.loadReleases(true);
        } catch (e) {
            console.log(e);
        }
    }

    async markModuleAsBuilt(module) {
        this.state = States.pending;
        await theclaw.reportModuleBuildSuccess(this.blocCode, this.collective.code, module.entityId);
        // Sleep for a second so that hopefully the backend is done when we reload.
        await new Promise(r => setTimeout(r, 1000));
        this.loadReleases(true);
    }

    async markModuleAsFailed(module) {
        this.state = States.pending;
        // Note this method mainly exists for testing purposes
        await theclaw.reportModuleBuildFailure(this.blocCode, this.collective.code, module.entityId);
        // Sleep for a second so that hopefully the backend is done when we reload.
        await new Promise(r => setTimeout(r, 1000));
        this.loadReleases(true);
    }

    /**
     * Starts autorefresh.
     * @param intervalKey The chosen interval.
     */
    async startAutoRefresh(intervalMillis) {
        let callback = () => this.loadReleases(false);
        // Run the callback right away since setInterval doesn't trigger until one interval has passed
        await callback();
        this.autoRefreshIntervalId = setInterval(callback, intervalMillis);
        this.setAutoRefresh(true);
    }

    setAutoRefresh(value) {
        this.autoRefreshEnabled = value;
    }

    stopAutoRefresh() {
        clearInterval(this.autoRefreshIntervalId);
        this.autoRefreshIntervalId = null;
        this.setAutoRefresh(false);
    }

    toggleAutoRefresh() {
        if (this.autoRefreshIntervalId == null) {
            this.startAutoRefresh();
        } else {
            this.stopAutoRefresh();
        }
    }

}

export default new ReleasesStore();
