'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';
import ActionButton from '../../components/ActionButton';
import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";

class ResetForm extends Component {

    static propTypes = {
        module: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
    }

    render() {
        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.RESET_REPO}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={`Clobber ${this.props.module}?`}
                    message={`This will destroy The Claw's working copy of this repo and re-clone from origin. Any local changes will be lost. Are you sure?`}
                    confirmText="Clobber"
                    onConfirm={() => ReleasesStore.resetModuleRepo(this.props.module)} />
            </>
        )
    }
}

export default ResetForm;
