'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";

import Form from 'react-bootstrap/Form';

import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import {withRouter} from "@uw-it-sis/lib-react/lib/WithRouter";
import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import TheClawStore from './TheClawStore';
import Modules from '../modules/Modules';
import Releases from '../releases/Releases';
import LogEntries from "../log/LogEntries";
import CollectiveForm from "../collectives/CollectiveForm";
import BlocForm from "../blocs/BlocForm";
import {capitalize} from "@uw-it-sis/lib-react/lib/Utils";
import ActionUtils from '../utils/ActionUtils';
const {
    UPDATE_BLOC,
    UPDATE_COLLECTIVE,
    NEW_COLLECTIVE,
    NEW_BLOC,
} = ActionUtils.Actions;
import ActionPopupButton from '../components/ActionPopupButton';

/**
 * The top level component for The Claw. Deals with selecting Blocs and Collectives.
 */
class TheClawApp extends Component {

    constructor(props) {
        super(props);
        this.store = TheClawStore;
        this.currentBloc = null;
        this.currentCollective = null;
        this._buildPage = this._buildPage.bind(this);
        this.onSelectBloc = this.onSelectBloc.bind(this);
        this.onSelectCollective = this.onSelectCollective.bind(this);
        this.onSelectPage = this.onSelectPage.bind(this);
    }

    componentDidMount() {
        // Parse current Bloc and Collective ...
        const currentBlocCode = this.props.params.blocCode;
        const currentCollectiveCode = this.props.params.collectiveCode;
        // Parse the page ...
        const page = this.props.params.page;
        if (page) {
            this.store.setCollectiveTab(page);
        }
        // Load the data.
        this.store.loadBlocs(currentBlocCode, currentCollectiveCode);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check for page changes ...
        const prevPage = prevProps.params.page;
        const newPage = this.props.params.page;
        if (newPage && prevPage !== newPage) {
            this.store.setCollectiveTab(newPage);
        }
        if (prevProps.location.pathname === "/" && this.store.state === States.done) {
            this._fixUrl(this.store?.getCurrentBloc()?.code, this.store?.getCurrentCollective()?.code);
        }
    }

    /**
     * Handler for the Bloc selector.
     * @param e The event.
     */
    onSelectBloc(e) {
        let selectedBloc = e.target.value;
        this.store.setCurrentBloc(selectedBloc);
        // Setting the bloc also sets the current collective, so go ahead and pass that in too.
        this._fixUrl(selectedBloc, this.store.getCurrentCollective()?.code, this.store.getCollectiveTab());
    }

    /**
     * Handler for the page selector.
     * @param e The event.
     */
    onSelectPage(page) {
        this.store.setCollectiveTab(page);
        // Setting the bloc also sets the current collective, so go ahead and pass that in too.
        this._fixUrl(this.store.getCurrentBloc().code, this.store.getCurrentCollective().code, this.store.getCollectiveTab());
    }

    /**
     * Handler for the collective selector.
     * @param e The event.
     */
    onSelectCollective(collectiveCode) {
        this.store.setCurrentCollective(collectiveCode);
        this._fixUrl(this.store.getCurrentBloc().code, collectiveCode, this.store.getCollectiveTab());
    }

    /**
     * Make the url align with the current bloc and collective selections.
     * @param blocCode The Bloc code to use in the URL.
     * @param collectiveCode The Collective code to use in the URL.
     * @private
     */
    _fixUrl(blocCode, collectiveCode, page) {
        let path = this.props.location.pathname;
        let search = this.props.location.search;
        let pathTokens = path.split("/");
        // Removed the first element which is always empty.
        pathTokens = pathTokens.slice(1);

        // The pathname will be something like "/foo/bar" so the array will be [undefined, "foo", "bar"], so replace the
        // number 1 element with the bloc code and the number 2 element with the new collective code.
        pathTokens[0] = blocCode;
        if (collectiveCode) {
            pathTokens[1] = collectiveCode;
            if (page) {
                pathTokens[2] = page;
            } else {
                pathTokens[2] = null;
            }
        } else {
            pathTokens[1] = null;
            pathTokens[2] = null;
        }

        // Joint it back up with "/" and strip the trailing "/"
        let newPath = `${pathTokens.join("/").replace(/\/$/, '')}`;
        // Create a new location object and replace the current history entry with it. push() is also available.
        let newLocation = {
            pathname: newPath,
            search: search
        }
        this.props.navigate(newLocation, {replace: true});
    }

    /**
     * The Blocs dropdown.
     * @param blocs
     * @param currentBlocCode The current bloc code.
     */
    _buildBlocSelector(blocs, currentBlocCode) {
        let options = blocs.map((bloc, index) => {
            return <option key={index} value={bloc.code}>{bloc.name}</option>;
        });

        return (
            <Form.Select
                className="my-2 my-sm-0 me-0 me-sm-3"
                name="blocSelector"
                onChange={this.onSelectBloc}
                defaultValue={currentBlocCode}>
                {options}
            </Form.Select>
        );
    }

    _buildCollectiveTabs(blocCode) {
        const collectives = this.store.getCollectives(blocCode);
        const currentCollective = this.store.getCurrentCollective();
        // Sometimes there won't be a current collective (e.g. you just added a Bloc).
        let currentCollectiveCode = null;
        if (currentCollective) {
            currentCollectiveCode = this.store.getCurrentCollective().code;
        }

        // Map collectives and create a tab for each collective. Only build out the body of the current collective.
        const collectivesTabs = collectives.map((collective, index) => {
            let collectiveTabBody = null;
            if (collective.code === currentCollectiveCode) {
                // Build the sub-tabs for the current collective ...
                const pageNavComponents = ['releases', 'modules', 'log'].map((collectiveTab, i) => {
                    // Only build the component for the active tab ...
                    if (this.store.getCollectiveTab() === collectiveTab) {
                        switch (this.store.getCollectiveTab()) {
                            case "modules":
                                collectiveTabBody = <Modules blocCode={blocCode} collective={currentCollective}/>;
                                break;
                            case "releases":
                                collectiveTabBody = <Releases blocCode={blocCode} collective={currentCollective}/>;
                                break;
                            case "log":
                                collectiveTabBody = <LogEntries blocCode={blocCode} collective={currentCollective}/>;
                                break;
                        }
                    }
                    return (
                        <LinkContainer key={i} to={`/${blocCode}/${currentCollectiveCode}/${collectiveTab}`}
                                       isActive={this.store.getCollectiveTab() === collectiveTab}>
                            <Nav.Link>{capitalize(collectiveTab)}</Nav.Link>
                        </LinkContainer>
                    );
                });

                collectiveTabBody =
                    <>
                        <Navbar bg="light" className="rounded-3 px-3 py-2 mb-3">
                            <Navbar.Toggle aria-controls="claw-navbar-nav" />
                            <Navbar.Collapse id="claw-navbar-nav" className="">
                                <Form className="d-flex align-items-center me-3">
                                    <ActionPopupButton action={UPDATE_COLLECTIVE}>
                                        <CollectiveForm mode="edit"
                                                        collective={currentCollective}
                                                        blocCode={this.store.getCurrentBloc().code} />
                                    </ActionPopupButton>
                                </Form>
                                <Nav className="align-items-center">{pageNavComponents}</Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        {collectiveTabBody}
                    </>;
            }

            return(
                <Tab key={index} eventKey={collective.code} title={collective.name}>
                     {collectiveTabBody}
                </Tab>);
        });

        return(
            <Tabs id="collectives-tabs" className="Xmb-3"
                  activeKey={currentCollectiveCode}
                  onSelect={this.onSelectCollective}>
                {collectivesTabs}
            </Tabs>
        );
    }

    _buildPage() {
        const blocs = this.store.getBlocs();
        const currentBloc = this.store.getCurrentBloc();
        const currentBlocCode = currentBloc.code;
        const blocSelector = this._buildBlocSelector(blocs, currentBlocCode);
        const collectives = this._buildCollectiveTabs(currentBlocCode);

        return (
            <>
                <Navbar bg="light" className="rounded-3 px-3 py-2 mb-3">
                    <Navbar.Brand>Bloc</Navbar.Brand>
                    <Navbar.Toggle aria-controls="claw-navbar-nav" />
                    <Navbar.Collapse id="claw-navbar-nav" className="">
                        <Form className="d-flex align-items-center me-3">
                            {blocSelector}
                            <ActionPopupButton action={UPDATE_BLOC}>
                                <BlocForm mode="edit" bloc={currentBloc} />
                            </ActionPopupButton>
                            <ActionPopupButton action={NEW_COLLECTIVE}>
                                <CollectiveForm mode="add" blocCode={currentBlocCode}/>
                            </ActionPopupButton>
                        </Form>
                        <Nav className="ms-auto">
                            <ActionPopupButton action={NEW_BLOC}>
                                <BlocForm mode="add"/>
                            </ActionPopupButton>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {collectives}
            </>
        );

    }

    render() {
        return (
            <SimpleState state={this.store.state} doneComponentBuilder={this._buildPage} name="TheClawApp"/>
        );
    }
}

export default withRouter(observer(TheClawApp));
