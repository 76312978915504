'use strict';

import React from 'react';
import PropTypes from 'prop-types';

/**
 * A React component for a single line item in a cell.
 * Lines using this component in the same Container should have their content
 * line up
 * @param {Object} props - The props object for the component
 * @param {string} props.name - The name of the line item
 * @param {any} props.val - The value of the line item
 */
function LineItem(props) {
    return (
        <li>
            <div className="title">{props.name}</div>
            <div>{props.val}</div>
        </li>
    );
}

LineItem.propTypes = {
    name: PropTypes.string.isRequired
}

export default LineItem;
