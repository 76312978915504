'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";

import BootstrapTable from 'react-bootstrap-table-next';

import Form from 'react-bootstrap/Form';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Badge from "react-bootstrap/Badge";
import {If, Then, Else} from "react-if";

import { States } from '@uw-it-sis/lib-react/lib/AppConstants';
import Loader from "@uw-it-sis/lib-react/lib/Loader";

import ModulesStore from './ModulesStore';
import ModuleForm from './ModuleForm';
import ModuleStatus from './ModuleStatus';

import {convertGitURL} from '../utils/utils';
import ActionUtils from "../utils/ActionUtils";
import ActionButton from "../components/ActionButton";
import ActionPopupButton from "../components/ActionPopupButton";

class Modules extends Component {

    static propTypes = {
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.store = ModulesStore;
        this._handleStatusButtonClick = this._handleStatusButtonClick.bind(this);
        this._buttonBuilder = this._buttonBuilder.bind(this);
    }

    componentDidMount() {
        this.store.loadModules(this.props.blocCode, this.props.collective.code);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check for page changes ...
        const prevBlocCode = prevProps.blocCode;
        const newBlocCode = this.props.blocCode;
        const prevCollectiveCode = prevProps.collective.code;
        const newCollectiveCode = this.props.collective.code;
        if (newBlocCode !== prevBlocCode || prevCollectiveCode !== newCollectiveCode) {
            this.store.loadModules(newBlocCode, newCollectiveCode);
        }
    }

    _buildTable = () => {
        let modules = this.store.getModules();

        // Each col needs a prop, so add actions prop
        let data = modules.map(m => ({...m, actions: []}) );

        let extraData = {blocCode: this.props.blocCode, collective: this.props.collective};

        /*
         * Formatter functions for specific columns
         */
        let requiredFormatter = required => required ? 'Yes' : '';

        // Format the git url to link to the repo
        let gitFormatter = gitUrl => {
            let httpsUrl = convertGitURL(gitUrl);
            return httpsUrl ? <a href={httpsUrl}>{gitUrl}</a> : gitUrl;
        }

        let statusFormatter = status => {
            let badgeVariant = null;
            switch (status) {
                case ModuleStatus.ACTIVE: badgeVariant = "success"; break;
                case ModuleStatus.INACTIVE: badgeVariant = "danger"; break;
                case ModuleStatus.DEPRECATED: badgeVariant = "warning"; break;
            }
            return (
                <Badge bg={badgeVariant}>{status}</Badge>
            );
        }

        let buildMonitoringFormatter = enabled => {
            let badgeVariant = enabled ? "success" : "danger";
            let text = enabled ? "Yes" : "No";
            return (
                <Badge bg={badgeVariant}>{text}</Badge>
            );
        }

        let columns = [
            {dataField: 'name',                   text: 'Name', sort:true},
            {dataField: 'gitUrl',                 text: 'Git URL', formatter: gitFormatter},
            {dataField: 'required',               text: 'Required', formatter: requiredFormatter},
            {dataField: 'buildPriority',          text: 'Build Priority', sort:true},
            {dataField: 'buildMonitoringEnabled', text: 'Build Status Reported', formatter: buildMonitoringFormatter},
            {dataField: 'status',                 text: 'Status', formatter: statusFormatter},
            {dataField: 'actions',                text: 'Actions', formatter: this._buttonBuilder, formatExtraData: extraData},
        ]

        return (
            <BootstrapTable
                    keyField="name"
                    columns={columns}
                    data={data}
                    classes="modules-table w-auto"
                    wrapperClasses="table-responsive"
                    bootstrap4 striped />
        );
    }

    /**
     * Handle module status change clicks.
     * @param module The module to update.
     * @private
     */
    _handleStatusButtonClick(module) {
        let newStatus = ModuleStatus.DEPRECATED;
        if (module.status !== ModuleStatus.ACTIVE) {
            newStatus = ModuleStatus.ACTIVE;
        }
        module.status = newStatus;
        this.store.updateModuleStatus(module);
    }

    /*
     * This is a formatter function passed to the react table for the actions column.
     * The returned JSX will be rendered in each cell of the actions column.
     */
    _buttonBuilder(cellData, row, rowIndex, extra) {
        let module = row;

        let action;

        switch (module.status) {
            case ModuleStatus.ACTIVE:
                action = ActionUtils.Actions.MODULE_DEPRECATE;
                break;
            case ModuleStatus.DEPRECATED:
            case ModuleStatus.INACTIVE:
                action = ActionUtils.Actions.MODULE_ACTIVATE;
                break;
        }

        return (
            <ButtonGroup className="btn-group-vertical-sm">
                <ActionPopupButton action={ActionUtils.Actions.EDIT_MODULE}>
                    <ModuleForm
                        mode="update"
                        blocCode={extra.blocCode}
                        collective={extra.collective}
                        module={module} />
                </ActionPopupButton>
                <ActionButton
                    action={action}
                    onClick={() => this._handleStatusButtonClick(module)} />
            </ButtonGroup>
        );
    }

    render() {
        return (
            <>
                {/* HEADER LINE */}
                <div className="d-flex align-items-center mb-3">
                    <ActionPopupButton action={ActionUtils.Actions.NEW_MODULE}>
                        <ModuleForm
                            mode="add"
                            blocCode={this.props.blocCode}
                            collective={this.props.collective} />
                    </ActionPopupButton>
                    <Form.Check
                            className="ms-4"
                            id="includeInactive"
                            name="includeInactive"
                            checked={this.store.includeInactive}
                            onChange={this.store.toggleIncludeInactive}
                            label="Include Inactive" />
                </div>

                {/* TABLE */}
                <Loader show={this.store.state == States.pending}>
                    <If condition={this.store.getModules().length === 0}>
                        <Then>
                            <div>No modules are currently configured</div>
                        </Then>
                        <Else>
                            {this._buildTable}
                        </Else>
                    </If>
                </Loader>
            </>
        )
    }

}

export default observer(Modules);
