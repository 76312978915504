'use strict';

import appStore from "@uw-it-sis/lib-react/lib/AppStore";

/*
 * An api client for The Claw. All api calls should be handled here
 */
class Api {

    base_url = "api"

    /*
     * ---------- BLOCS ----------
     */
    listBlocs() {
        return appStore.getHttpClient().doGet(`${this.base_url}/blocs`);
    }

    createBloc(blocCode, name, description) {
        let body = {name: name, code: blocCode};
        if (description) {
            body.description = description;
        }
        return appStore.getHttpClient().doPost(`${this.base_url}/blocs`, body);
    }

    updateBloc(data) {
        let id = data.id;
        return appStore.getHttpClient().doPut(`${this.base_url}/blocs/${id}`, data);
    }

    deleteBloc(id) {
        return appStore.getHttpClient().doDelete(`${this.base_url}/blocs/${id}`);
    }

    /*
     * ---------- COLLECTIVES ----------
     */
    listCollectives(blocCode) {
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/collectives`);
    }

    createCollective(blocCode, data) {
        // Remove the id prop.
        delete data["id"];
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/collectives`, data,
            "Create collective", true);
    }

    updateCollective(blocCode, data) {
        let id = data.id;
        return appStore.getHttpClient().doPut(`${this.base_url}/${blocCode}/collectives/${id}`, data,
            "Update collective", true);
    }

    deleteCollective(blocCode, id) {
        return appStore.getHttpClient().doDelete(`${this.base_url}/${blocCode}/collectives/${id}`);
    }

    /*
     * ---------- LOG ENTRIES ----------
     */

    getLogEntries(blocCode, collectiveCode) {
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/log`);
    }

    /*
     * ---------- RELEASES ----------
     */
    listReleases(blocCode, collectiveCode, includeActions) {
        let actionsSuffix = includeActions ? "?actions=true" : "";
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/releases${actionsSuffix}`);
    }

    getReleaseByIdentifier(blocCode, collectiveCode, identifier) {
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/releases/${identifier}`);
    }

    promoteRelease(blocCode, collectiveCode, releaseId) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/releases/${releaseId}/promote`)
    }

    createFutureRelease(blocCode, collectiveCode, data) {
        // POST /<cid>/releases?type=future
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/releases?type=future`, data);
    }

    createPatchRelease(blocCode, collectiveCode, body) {
        // POST /<cid>/releases?type=patch
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/releases?type=patch`, body);
    }

    updateRelease(blocCode, collectiveCode, release) {
        // This release object is constructed from form data instead of the display object.
        let id = release.id;
        return appStore.getHttpClient().doPut(`${this.base_url}/${blocCode}/${collectiveCode}/releases/${id}`, release);
    }

    deleteRelease(blocCode, collectiveCode, releaseId) {
        return appStore.getHttpClient().doDelete(`${this.base_url}/${blocCode}/${collectiveCode}/releases/${releaseId}`);
    }

    /*
     * ---------- RELEASE MODULES ----------
     */

    removeModuleFromRelease(blocCode, collectiveCode, releaseId, moduleId) {
        let url = `${this.base_url}/${blocCode}/${collectiveCode}/releases/${releaseId}/modules/${moduleId}`;
        return appStore.getHttpClient().doDelete(url);
    }

    /*
     * Adds a module to a release.
     * The module param object should have these fields: name, releaseId, version, description
     */
    addModuleToRelease(blocCode, collectiveCode, releaseId, module) {
        let url = `${this.base_url}/${blocCode}/${collectiveCode}/releases/${releaseId}/modules/${module.name}`;
        return appStore.getHttpClient().doPost(url, module);
    }

    /*
     * Updates a module to a release.
     * The module param object should have these fields: name, releaseId, version, description
     */
    updateModuleForRelease(blocCode, collectiveCode, releaseId, module) {
        let url = `${this.base_url}/${blocCode}/${collectiveCode}/releases/${releaseId}/modules/${module.name}`;
        return appStore.getHttpClient().doPut(url, module);
    }

    getVersionOptions(blocCode, collectiveCode, releaseId) {
        let releaseSuffix = releaseId ? `?releaseId=${releaseId}` : ""
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/releases/nextVersionOptions${releaseSuffix}`);
    }

    /*
     * ---------- MODULES ----------
     */

    listModules(blocCode, collectiveCode) {
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/modules`);
    }

    getModule(blocCode, collectiveCode, moduleId) {
        return appStore.getHttpClient().doGet(`${this.base_url}/${blocCode}/${collectiveCode}/modules/${moduleId}`);
    }

    createModule(blocCode, collectiveCode, moduleData) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/modules`, moduleData);
    }

    updateModule(blocCode, collectiveCode, moduleData) {
        let moduleId = moduleData.id;
        return appStore.getHttpClient().doPut(`${this.base_url}/${blocCode}/${collectiveCode}/modules/${moduleId}`, moduleData);
    }

    /*
     * ---------- REFRESH ----------
     */

    refreshRepo(blocCode, collectiveCode, moduleName) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/modules/${moduleName}/refresh`);
    }

    refreshAllRepos(blocCode, collectiveCode) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/modules/refresh`);
    }

    /*
     * ---------- RESET ----------
     */

    resetRepo(blocCode, collectiveCode, moduleName) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/modules/${moduleName}/reset`);
    }

    resetAllRepos(blocCode, collectiveCode) {
        return appStore.getHttpClient().doPost(`${this.base_url}/${blocCode}/${collectiveCode}/modules/reset`);
    }

    /*
     * ---------- PROMOTION ----------
     */
    reportModuleBuildSuccess(blocCode, collectiveCode, moduleId) {
        return appStore.getHttpClient().doPut(`${this.base_url}/${blocCode}/${collectiveCode}/builds/modules/${moduleId}/success`);
    }

    reportModuleBuildFailure(blocCode, collectiveCode, moduleId) {
        return appStore.getHttpClient().doPut(`${this.base_url}/${blocCode}/${collectiveCode}/builds/modules/${moduleId}/failure`);
    }
}

export default new Api();
