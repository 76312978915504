'use strict';

import { observable, configure, action, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import theclaw from '../utils/Api';
import ModulesStore from './ModulesStore';
import ModuleStatus from './ModuleStatus';
import { ModalSubmitError } from '../components/ClawModalLib';

class ModuleFormStore {
    state = States.initial;
    setState = (state) => this.state = state;

    // The type of form - whether we're creating or editing a module
    mode = null;

    // the data entered into the form
    formData = ModuleFormStore._getDefaultFormData();

    constructor() {
        makeObservable(this, {
            state: observable,
            setState: action,
            mode: observable,
            formData: observable,
            mount: action,
            updateFormData: action,
        });
    }

    /*
     * Mode should be one of: add, update
     * Module can be null, if we are creating a new one
     */
    mount(blocCode, collective, module, mode) {
        this.mode = mode;
        this.blocCode = blocCode;
        this.collective = collective;

        // copy over properties
        if (module) {
            for (let prop of [
                'status', 'finalRelease', 'gitUrl', 'id', 'lastDeployedBranch', 'buildMonitoringEnabled',
                'buildPriority', 'name', 'required']
            ) {
                this.formData[prop] = module[prop];
            }
        }
        this.state = States.done;
    }

    /*
     * Updates our internal representation of the data in the form
     */
    updateFormData(property, value) {
        this.formData[property] = value;
    }

    /*
     * Handles an update or create form submission
     */
    submit = async () => {
        switch (this.mode) {
            case ModuleFormStore.MODE.ADD:
                await this._createModule();
                break;
            case ModuleFormStore.MODE.UPDATE:
                await this._updateModule();
                break;
        }
    }

    /*
     * Return true if the data in the form is valid
     * For now, only checks that name and url have been filled out
     */
    isValid() {
        return this.formData.name.length !== 0 && this.formData.gitUrl.length !== 0;
    }

    /**
     * This is the starting formData object
     */
    static _getDefaultFormData() {
        return ({
            id: undefined,
            name: "",
            gitUrl: "",
            buildPriority: 50,
            buildMonitoringEnabled: false,
            status: ModuleStatus.ACTIVE,
            lastDeployedBranch: null,
            required: false,
            finalRelease: null,
        });
    }

    async _createModule() {
        try {
            let module = this.formData;
            this.setState(States.pending);
            await theclaw.createModule(this.blocCode, this.collective.code, module);
        } catch (e) {
            console.error(`Failure creating module ${this.formData.name}`)
            console.log(e)
            this.state = States.done;
            // Throw an error to tell the modal not to close.
            throw new ModalSubmitError(e.message, {cause:e});
        }
        ModulesStore.loadModules(this.blocCode, this.collective.code);
    }

    async _updateModule() {
        try {
            let module = this.formData;
            this.setState(States.pending);
            await theclaw.updateModule(this.blocCode, this.collective.code, module);
        } catch (e) {
            console.error(`Failure updating module ${this.formData.name}`)
            console.log(e)
            this.state = States.done;
            // Throw an error to tell the modal not to close.
            throw new ModalSubmitError(e.message, {cause:e});
        }
        ModulesStore.loadModules(this.blocCode, this.collective.code);
    }
}

ModuleFormStore.MODE = {
    ADD : "add",
    UPDATE : "update",
}


export default ModuleFormStore;
