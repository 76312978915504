'use strict';

import { observable, configure, action, flow, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import ReleasesStore from './ReleasesStore';
import { ANY_VERSION } from '../utils/Constants';

import {values} from 'lodash';

import theclaw from '../utils/Api';

class ReleaseFormStore {

    constructor() {
        makeObservable(this, {
            state: observable,
            formData: observable,
            updateFormData: action,
            mode: observable,
            release: observable,
            versionOptions: observable,
            submit: action,
            mount: action,
        });

        this.blocCode = null;
        this.collective = null;
    }

    state = States.initial;

    // The type of form - whether we're creating a new release, new patch, or
    // editing an existing release
    mode = null;

    // the data entered into the form
    formData = ReleaseFormStore._getDefaultFormData();

    // A reference to the release we're editing. Will be null if mode is ADD or PATCH
    release = null;

    // The options available for version incrementing
    versionOptions = [];

    /*
     * Initialize the form.
     * @param mode Mode should be one of: add, update, patch
     * @param release The release to update. Null if we are creating a new one.
     * @param collective The collective to create the release under. The release model has
     *                   collective data as part of the model, but is null on create.
     * @param blocCode The bloc code for the collective.
     *
     */
    mount(mode, release, collective, blocCode) {
        this.mode = mode
        this.release = release
        this.collective = collective;
        this.blocCode = blocCode;
        this.state = States.done;

        this.initialize();
    };

    initialize = flow(function* () {
        this.state = States.pending;

        yield this._setVersionOptions().then(action(() => {
            if (this.versionOptions.includes(ANY_VERSION)) {
                // This collective hasn't been released before, so the user can put whatever they want, but default to 1.0.0
                this.formData.productVersion = this.release ? this.release.productVersion : "1.0.0";
            } else {
                this.formData.productVersion = this.versionOptions[0];
            }

            // Copy over any existing properties from the current release (if
            // we aren't making a new one) to the local store. We can't just
            // hold a reference to the release bc if something changes, mobx
            // will complain that we're modifying observed data.
            if (this.mode === ReleaseFormStore.MODE.UPDATE && this.release != null) {
                let props = ['status', 'releaseDate', 'releaseName', 'description']
                for (let property of props) {
                    if (property in this.release && this.release[property] != null) {
                        this.formData[property] = this.release[property];
                    }
                }

                // some props aren't symmetric btwn what the releases api returns
                // and what the update release api wants
                this.formData.id = this.release.entityId

                if (this.release.releaseRepositoryMetadata) {
                    this.formData.branch = this.release.releaseRepositoryMetadata.branch.toLowerCase();
                }

                // Only set the version to the old one if it's actually an option
                if (this.versionOptions.includes(this.release.productVersion)) {
                    this.formData.productVersion = this.release.productVersion;
                }
            }
        }));

        this.state = States.done;

    }.bind(this));

    getState = () => this.state;
    setState = action(state => this.state = state);

    /*
     * Updates our internal representation of the data in the form
     */
    updateFormData(property, value) {
        this.formData[property] = value;
    }

    /*
     * Sets the version options for this release
     * This happens asynchronously and returns a promise
     * @return Promise - when resolved, `this.versionOptions` will be set
     */
    _setVersionOptions() {
        let releaseId = this.release ? this.release.entityId : null;
        return theclaw.getVersionOptions(this.blocCode, this.collective.code, releaseId).then(
            action(options => {
                this.versionOptions = values(options.release);
                // Have to use slice since this array is observable.
                this.versionOptions.replace(this.versionOptions.slice().sort());
            })
        )
    }

    /*
     * Returns true if the user can adjust the version for this release
     * TODO SISENG-507 - this logic should be moved into the server code
     */
    get canSetVersion() {
        // Only allow the release version to be selected on adds and updates of future releases.
        if (this.mode === "add") {
            return true;
        } else if (this.mode === "update" && this.release.status === "FUTURE") {
            return true;
        } else {
            return false;
        }
    }

    /*
     * Handles an update or create form submission
     */
    submit = async () => {
        // The 'create patch release' api call takes a long time to return,
        // so the modal just sits there and you can bang on the submit button
        // until it returns. We'll set the app state to pending to make it
        // clear we're waiting on the server. Each of these api actions will
        // call loadReleases after they're done which will reload the UI and
        // set it back to state==done
        ReleasesStore.setState(States.pending);
        this.setState(States.pending);
        switch (this.mode) {
            case ReleaseFormStore.MODE.ADD:
                await this._addRelease();
                break;
            case ReleaseFormStore.MODE.UPDATE:
                await this._updateRelease();
                break;
            case ReleaseFormStore.MODE.PATCH:
                await this._createPatchRelease();
                break;
        }
        this.setState(States.done);
    };

    /*
     * Create a new release
     */
    async _addRelease() {
        try {
            await theclaw.createFutureRelease(this.blocCode, this.collective.code, this.formData);
        } catch (e) {
            console.error(`Failure creating release ${this.formData.releaseName}`)
            console.log(e)
        }

        ReleasesStore.loadReleases(true);
    }

    async _updateRelease() {
        try {
            await theclaw.updateRelease(this.blocCode, this.collective.code, this.formData);
        } catch (e) {
            console.error(`Failure updating release ${this.formData.releaseName}`)
            console.log(e)
        }

        ReleasesStore.loadReleases(true);
    }

    async _createPatchRelease() {
        // NOTE: There's something funny about making patch releases. With
        // other releases, you have to specify the version you want the new (or
        // edited) release to have, but with patch releases, you pass in the
        // version you want to be patched (or so it seems). For example, if the
        // current master release is version 3.1.4 and you want to create patch
        // release 3.1.5, you have to pass in version 3.1.4 to the api and
        // 3.1.5 will be created.
        try {
            await theclaw.createPatchRelease(this.blocCode, this.collective.code, this.formData);
        } catch (e) {
            console.error(`Failure creating patch release ${this.formData.releaseName}`)
            console.log(e)
        }

        ReleasesStore.loadReleases(true);
    }

    /*
     * Return true if the data in the form is valid
     * For now, only checks that the releaseName has been filled out
     *
     * NOTE: this can get called before mount()
     */
    isValid() {
        if (this.collective?.releaseNamingEnabled) {
            return this.formData.releaseName.length !== 0
        }
        return true;
    }

    /**
     * This is the starting formData object
     */
    static _getDefaultFormData() {
        return ({
            status: "FUTURE",
            branch: "",
            id: undefined,
            releaseName: "",
            productVersion: "",
            releaseDate: "",
            description: ""
        });
    }
}

ReleaseFormStore.MODE = {
    ADD : "add",
    UPDATE : "update",
    PATCH : "patch",
}

export default ReleaseFormStore;
