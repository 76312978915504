'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {isEqual} from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import BlocFormStore from './BlocFormStore';

import {
    ClawModal,
    ClawModalHeader,
    ClawModalBody,
    ClawModalFooter,
    ClawForm,
} from '../components/ClawModalLib';

/*
 * This Form is used to add or edit Blocs
 */
class BlocForm extends Component {

    static propTypes = {
        mode: PropTypes.string.isRequired,
        enity: PropTypes.object, // null if we're creating a new one
    }

    constructor(props) {
        super(props);
        this.store = new BlocFormStore();
    }

    componentDidMount() {
        this.store.mount(this.props.mode, this.props.bloc);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Re-mount the store if the Bloc has been changed
        if (! isEqual(prevProps.bloc, this.props.bloc)) {
            this.store.mount(this.props.mode, this.props.bloc);
        }
    }

    /**
     * Handle input widget changes.
     */
    handleFormChange = (e) => {
        const property = e.target.name;
        const value = e.target.value;
        this.store.updateFormData(property, value);
    }

    _buildModalBody() {
        let errorMessage = null;
        if (this.store.getErrorMessage()) {
            errorMessage =
                <Alert variant={"danger"} dismissible>
                    <ul>{this.store.getErrorMessage()}</ul>
                </Alert>;
        }

        return (
            <>
                {errorMessage}
                <ClawForm
                    onSubmit={this.store.submit}
                    state={this.store.state}>
                    {/*
                      * CODE
                      */}
                    <Form.Group controlId="code" >
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                                required
                                name="code"
                                type="text"
                                value={this.store.formData.code}
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>A unique code for the Bloc. This will be used in repo names and The Claw's apis.</Form.Text>
                    </Form.Group>

                    {/*
                      * NAME
                      */}
                    <Form.Group controlId="name" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                                required
                                name="name"
                                type="text"
                                value={this.store.formData.name}
                                placeholder="Foo"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>The friendly name of the Bloc as it is displayed in The Claw.</Form.Text>
                    </Form.Group>

                    {/*
                      * DESCRIPTION
                      */}
                    <Form.Group controlId="description" >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                                name="description"
                                type="text"
                                value={this.store.formData.description}
                                placeholder="Description"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback/>
                        <Form.Text>A short description of this Bloc.</Form.Text>
                    </Form.Group>
                </ClawForm>
            </>
        )
    }

    render() {
        let modalTitle;
        switch(this.store.mode) {
            case BlocFormStore.MODE.ADD: modalTitle = "Create Bloc"; break;
            case BlocFormStore.MODE.EDIT: modalTitle = `Edit Bloc`; break;
        }

        return (
            <ClawModal id="BlocFormModal">
                <ClawModalHeader title={modalTitle}/>
                <ClawModalBody>
                    {this._buildModalBody()}
                </ClawModalBody>
                <ClawModalFooter
                    onSubmit={this.store.submit}
                    onReset={this.store.reset}
                    loadingState={this.store.state}
                    canSubmit={this.store.isValid()}
                />
            </ClawModal>
        )
    }
}

export default observer(BlocForm);
