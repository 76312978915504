'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {sortBy, keyBy} from 'lodash';

import {FaArrowRight, FaArrowDown} from "react-icons/fa";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

// The far-left cell of the second (non-header) table row should say "Modules"
// with a down arrow. Since we can't easily set the JSX for this directly, the
// formatter function for the first column will look for this flag and insert
// the necessary JSX
const MODULE_HEADER_FLAG = "Modules vvv";

/**
 * A table showing a list of releases
 */
class ReleasesTable extends Component {

    static propTypes = {
        data: PropTypes.object.isRequired,
        formatter: PropTypes.object.isRequired,
        moduleColumnFormatter: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.props = props;
    }

    /*
     * Documentation for the react-bootstrap-table-next (aka
     * react-bootstrap-table2) lib is scarce and obtuse, especially for how
     * it wants the input data to be formatted... here are some notes:
     *
     * 'columns' is a list of objects, where each object needs the props
     * 'dataField' and 'text'. The 'formatter' prop is a function that takes
     * the data for each cell and returns a string or JSX
     *
     * 'data' is a list of objects, where each object represents a row in the
     * table. Each 'row object' needs to have a property for each column, where
     * the prop key matches the 'dataField' value of the column.
     */
    _makeComponent() {
        // A function for formatting the header cells of release columns
        let hf = (col) => {
            return ( <span className="h4 mb-0">{col.text}</span> );
        }

        // Build the Release headers
        let columns = this.props.data.releases.map(r => {
            let releaseName = "";
            if (r.releaseName) {
                releaseName = ` (${r.releaseName})`;
            }

            return {
                dataField: r.productVersion,
                text: `${r.productVersion}${releaseName}`,
                formatter: this.props.formatter.format,
                headerFormatter: hf,
                formatExtraData: {release: r},
            }
        });

        /*
         * Set up the far left column.  Since the lib doesn't accept jsx
         * directly, we'll hack it in through the formatter functions
         */

        // This is the top left most cell
        let releasesCell = (
            <div className="d-flex align-items-center justify-content-end">
                Releases
                <FaArrowRight aria-hidden={true} className="ms-2" />
            </div>
        );

        // This is the second cell from the top, in the left column
        let moduleCell = (
            <>
                <strong>Modules</strong>
                <FaArrowDown aria-hidden={true} className="ms-2"/>
            </>
        );

        let ModulesFormatter = (cell, row) => {
            if (cell === MODULE_HEADER_FLAG) {
                return moduleCell;
            } else if (this.props.moduleColumnFormatter) {
                return this.props.moduleColumnFormatter(cell, row.moduleObj);
            } else {
                return cell
            }
        }

        // Insert the leading column of module names
        columns.splice(0, 0, {
            dataField: "module",
            text: "NotDisplayed",
            headerFormatter: () => releasesCell,
            formatter: ModulesFormatter,
            attrs: () => ({'scope':'row'})
        });

        let rowData = sortBy(this.props.data.modules, m => m.module)

        // Insert the top row for general release info
        rowData.splice(0, 0, keyBy(this.props.data.releases, r => r.productVersion));
        rowData[0].module = MODULE_HEADER_FLAG;

        return(
            <ToolkitProvider
                keyField="module"
                data={rowData}
                columns={columns}
                bootstrap4>
                {
                    props => (
                        <BootstrapTable
                            {...props.baseProps}
                            classes="releases-table w-auto"
                            wrapperClasses="table-responsive"
                            striped
                            bootstrap4 />
                    )
                }
            </ToolkitProvider>);
    }

    render () {
        return (
            <>
                {this._makeComponent()}
            </>
        );
    }
}

export default ReleasesTable;
