'use strict';

/**
 * Module status constants.
 */
const ModuleStatus  = {
    ACTIVE: "ACTIVE",
    DEPRECATED: "DEPRECATED",
    INACTIVE: "INACTIVE"
};

module.exports = ModuleStatus;
