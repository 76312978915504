'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import Form from 'react-bootstrap/Form';
import {When} from "react-if";

import ReleaseFormStore from '../ReleaseFormStore';
import {isValidVersion} from '../../utils/utils';
import { ANY_VERSION } from '../../utils/Constants';

import {
    ClawModal,
    ClawModalHeader,
    ClawModalBody,
    ClawModalFooter,
    ClawForm,
} from '../../components/ClawModalLib';


const RELEASE_FORM_PROPTYPES = {
    blocCode: PropTypes.string.isRequired,
    collective: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    release: PropTypes.object, // not required bc it could be null if we're creating a new release
}

/*
 * This Form is used to add new releases and patch releases, as well as edit
 * existing releases.
 */
class ReleaseForm extends Component {

    static propTypes = RELEASE_FORM_PROPTYPES;

    constructor(props) {
        super(props);
        this.store = new ReleaseFormStore();
    }

    componentDidMount() {
        this.store.mount(
            this.props.mode,
            this.props.release,
            this.props.collective,
            this.props.blocCode
        );
    }

    /**
     * Handle input widget changes.
     */
    handleFormChange = (e) => {
        let property = e.target.name;
        let value = e.target.value
        this.store.updateFormData(property, value);
    }

    _buildModalBody() {
        // Build the version picker

        // Because of an oddity in the create patch api, the version of the
        // to-be-patched release would be shown instead of the version of the
        // newly-created release - so we'll just not show it. (It's not like
        // there was really much of a choice here anyways)
        let productVersion = null;
        if (this.store.mode === ReleaseFormStore.MODE.PATCH) {
            productVersion = null;
        } else if (this.store.versionOptions.includes(ANY_VERSION)) {
            // If this is the first release in a new collective, you can pick any starting version (but you should
            // probably pick 1.0.0)
            productVersion = (
                <>
                    <Form.Control
                        name="productVersion"
                        type="text"
                        value={this.store.formData.productVersion}
                        isInvalid={!isValidVersion(this.store.formData.productVersion)}
                        onChange={this.handleFormChange}
                    />
                    <Form.Control.Feedback type="invalid" aria-live="polite">
                        Not a valid version format.
                    </Form.Control.Feedback>
                    <Form.Text>The collective version for the release (e.g. 4.1.0).</Form.Text>
                </>
            )
        } else {
            let versionOptions = this.store.versionOptions.map( (version, i) => {
                return <option key={i} value={version}>{version}</option>;
            });
            productVersion =
                <Form.Group controlId="productVersion">
                    <Form.Label>Collective Version</Form.Label>
                    <Form.Select
                        value={this.store.formData.productVersion}
                        name="productVersion"
                        disabled={!this.store.canSetVersion}
                        onChange={this.handleFormChange}>
                        {versionOptions}
                    </Form.Select>
                    <Form.Text>The collective version for the release (e.g. 4.1.0).</Form.Text>
                </Form.Group>;
        }

        return (
            <ClawForm
                onSubmit={this.store.submit}
                state={this.store.state}>

                {/* RELEASE NAME */}
                {/* (only show if release naming is enabled.) */}
                <When condition={this.props.collective.releaseNamingEnabled}>
                    <Form.Group controlId="releaseName" >
                        <Form.Label>Release Name</Form.Label>
                        <Form.Control
                                required
                                name="releaseName"
                                type="text"
                                value={this.store.formData.releaseName}
                                placeholder="Carrot Pie"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>Release naming convention: {this.props.collective.releaseNamingDescription}</Form.Text>
                    </Form.Group>
                </When>

                {/* COLLECTIVE / PRODUCT VERSION */}
                {productVersion}

                {/* RELEASE DATE */}
                <Form.Group controlId="releaseDate" >
                    <Form.Label>Release Date</Form.Label>
                    <Form.Control
                            name="releaseDate"
                            type="text"
                            value={this.store.formData.releaseDate}
                            placeholder="2020-12-31"
                            onChange={this.handleFormChange} />
                    <Form.Control.Feedback/>
                    <Form.Text>The timeframe of the release (e.g. 2022-21-31, 2018-01).</Form.Text>
                </Form.Group>

                {/* DESCRIPTION */}
                <Form.Group controlId="description" >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                            name="description"
                            type="text"
                            value={this.store.formData.description}
                            placeholder="Adding denatured polymorphism"
                            onChange={this.handleFormChange} />
                    <Form.Text>A summary of what's in this release.</Form.Text>
                </Form.Group>
            </ClawForm>
        );
    }

    render() {
        let modalTitle;
        switch (this.props.mode) {
            case "add":
                modalTitle = "Create Release";
                break;
            case "patch":
                modalTitle = "Create Patch Release";
                break;
            case "update":
                let releaseName = this.props.release.releaseName;
                let releaseVersion = this.props.release.productVersion

                let releaseText = releaseName == "" ? releaseVersion : `${releaseName} (v${releaseVersion})`;
                modalTitle = `Update Release ${releaseText}`;
                break;
        }

        // Building the rest of the form
        return (
            <ClawModal>
                <ClawModalHeader title={modalTitle}/>
                <ClawModalBody>
                    {this._buildModalBody()}
                </ClawModalBody>
                <ClawModalFooter
                    onSubmit={this.store.submit}
                    loadingState={this.store.state}
                    canSubmit={this.store.isValid()}
                    />
            </ClawModal>
        );
    }
}

export default observer(ReleaseForm);
