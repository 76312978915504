'use strict';

import { observable, configure, action, flow, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import theclaw from '../utils/Api';
import theClawStore from '../app/TheClawStore';
import { ModalSubmitError } from '../components/ClawModalLib';

class CollectiveFormStore {
    // The type of form - whether we're creating or editing a collective
    mode = null;

    // the data entered into the form
    formData = CollectiveFormStore._getDefaultFormData();

    state = States.initial;

    errorMessage = "";

    originalCollective = {};

    constructor() {
        makeObservable(this, {
            state: observable,
            submit: action,
            mode: observable,
            formData: observable,
            mount: action,
            updateFormData: action,
            reset: action,
        });
    }

    /*
     * Mode should be one of: add, update, patch
     * Release can be null, if we are creating a new one
     */
    mount(mode, collective, blocCode) {
        this.mode = mode;
        this.blocCode = blocCode;
        this.originalCollective = collective;

        // Fill in the form data if there's a collective object and the mode is edit.
        // Otherwise, use the form defaults ...
        this.formData = CollectiveFormStore._getDefaultFormData();
        if (collective) {
            this.reset();
        }
        this.state = States.done;
    }

    /*
     * Updates our internal representation of the data in the form
     */
    updateFormData(property, value) {
        this.formData[property] = value;
    }

    /*
     * Handles an update or create form submission
     */
    submit = flow(function* () {
        this.state = States.pending;
        this.updateFormData('code', this.formData.code.toLowerCase());
        try {
            switch (this.mode) {
                case CollectiveFormStore.MODE.ADD:
                    let c = this.formData;
                    yield theclaw.createCollective(this.blocCode, this.formData);
                    break;
                case CollectiveFormStore.MODE.EDIT:
                    yield theclaw.updateCollective(this.blocCode, this.formData);
                    break;
            }
            theClawStore.loadBlocs(this.blocCode, this.formData.code);
        } catch(error) {
            this.errorMessage = error.message;
            this.state = States.done;
            // Throw an error to tell the modal not to close.
            throw new ModalSubmitError(error.message, {cause:error});
        }
        this.state = States.done;
    }.bind(this));

    getErrorMessage() {
        return this.errorMessage;
    }

    /**
     * Resets the form data back to the data from the call to mount().
     */
    reset = () => {
        if (this.originalCollective) {
            this.formData.id = this.originalCollective.id;
            this.formData.code = this.originalCollective.code;
            this.formData.name = this.originalCollective.name;
            this.formData.description = this.originalCollective.description || "";
            this.formData.buildSequencingEnabled = this.originalCollective.buildSequencingEnabled;
            this.formData.releaseNamingEnabled = this.originalCollective.releaseNamingEnabled;
            this.formData.releaseNamingDescription = this.originalCollective.releaseNamingDescription || "";
        } else {
            this.formData = CollectiveFormStore._getDefaultFormData();
        }
        this.errorMessage = "";
    };

    /*
     * Return true if the data in the form is valid
     * For now, only checks that the name has been filled out
     */
    isValid() {
        return !! (this.formData.name.length != 0 &&
            this.formData.code.length != 0 &&
            this.formData.code.match(/^[a-zA-Z0-9\-_]+$/));
    }

    /**
     * This is the starting formData object
     */
    static _getDefaultFormData() {
        return ({
            id: "",
            name: "",
            code: "",
            description: "",
            buildSequencingEnabled: false,
            releaseNamingEnabled: false,
            releaseNamingDescription: ""
        });
    }
}

CollectiveFormStore.MODE = {
    ADD : "add",
    EDIT : "edit",
}

export default CollectiveFormStore;
