'use strict';

import {reduce, keyBy} from 'lodash';

/**
 * Takes an array of Bloc data and creates a map of Blocs and Collectives by their codes since that's the main way we
 * access them. The resulting object looks like ...
 *
 * {
 *     "blocCode": {
 *         bloc: {...}
 *         collectives: {
 *             "collectiveCode": {
 *                 collective: {...}
 *             }
 *             ...
 *         }
 *     }
 *     ...
 * }
 */
export function mapBlocs(blocData) {
    return reduce(blocData, function(accumulator, bloc) {
        // Create a map of the collectives wihin this Bloc.
        const collectives = keyBy(bloc.collectives, function(collective) {
            return collective.code;
        });
        var blocMap = {};
        // Remove the existing collectives property from the bloc object (which was processed above)
        delete bloc["collectives"];
        blocMap[bloc.code] = {"bloc": bloc, "collectives": collectives};
        return {...accumulator, ...blocMap};
    }, {});
}