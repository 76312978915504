'use strict';

import { flow, action, observable, configure, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import theclaw from '../utils/Api';

/**
 * Store for the collective app log.
 */
class LogStore {

    constructor() {
        makeObservable(this, {
            state: observable
        });

        this.logEntries = [];
    }

    state = States.initial;

    /**
     * Make a service call. Note: This is a generator function.
     */
    loadData = flow(function* (blocCode, collectiveCode) {
        this.state = States.pending;
        try {
            this.logEntries = yield theclaw.getLogEntries(blocCode, collectiveCode);
            // Add an index to be used as a unique key. Assume the entries are sorted oldest to newest.
            this.logEntries = this.logEntries.map((entry, index) => {
                entry.index = index;
                return entry;
            });
            this.state = States.done;
        } catch(error) {
            this.state = States.error;
        }
    }.bind(this));

    getLogEntries() {
        return this.logEntries;
    }
}

export default new LogStore();
