'use strict';

import { observable, configure, action, flow, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import theclaw from '../utils/Api';
import theClawStore from '../app/TheClawStore';

class BlocFormStore {

    // The type of form - whether we're creating or editing a Bloc
    mode = null;

    // the data entered into the form
    formData = BlocFormStore._getDefaultFormData();

    state = States.initial;

    // The bloc data that the form was mounted with.
    bloc = {};

    constructor() {
        makeObservable(this, {
            state: observable,
            submit: action,
            reset: action,
            mode: observable,
            formData: observable,
            mount: action,
            updateFormData: action,
        });
    }

    /*
     * Mode should be one of: add, update, patch
     * Release can be null, if we are creating a new one
     */
    mount(mode, bloc) {
        this.bloc = bloc;
        this.mode = mode;
        this.originalBloc = bloc;
        this.errorMessage = "";
        // Clear the form data on mount ...
        this.formData = BlocFormStore._getDefaultFormData();
        // Set the form data if a bloc is provided (indicating an edit)
        if (bloc) {
           this.reset();
        }
        this.state = States.done;
    }

    /*
     * Updates our internal representation of the data in the form
     */
    updateFormData(property, value) {
        this.formData[property] = value;
    }

    /*
     * Handles an update or create form submission
     */
    submit = flow(function* () {
        this.state = States.pending;
        this.updateFormData('code', this.formData.code.toLowerCase());
        try {
            switch (this.mode) {
                case BlocFormStore.MODE.ADD:
                    yield theclaw.createBloc(this.formData.code, this.formData.name, this.formData.description);
                    break;
                case BlocFormStore.MODE.EDIT:
                    yield theclaw.updateBloc(this.formData);
                    break;
            }
            theClawStore.loadBlocs(this.formData.code);
        } catch(error) {
            this.errorMessage = error.message;
        }

        this.state = States.done;
    }.bind(this));

    getErrorMessage() {
        return this.errorMessage;
    }

    /**
     * Resets the form data back to the data from the call to mount().
     */
    reset = () => {
        if (this.bloc) {
            this.formData.id = this.bloc.id;
            this.formData.code = this.bloc.code;
            this.formData.name = this.bloc.name;
            this.formData.description = this.bloc.description || "";
        } else {
            this.formData = BlocFormStore._getDefaultFormData();
        }
        this.errorMessage = "";
    };

    /*
     * Return true if the data in the form is valid
     */
    isValid() {
        return this.formData.name.length != 0 &&
            this.formData.code.length != 0 &&
            this.formData.code.match(/^[a-zA-Z0-9\\-_]+$/);
    }

    /**
     * This is the starting formData object
     */
    static _getDefaultFormData() {
        return ({
            id: "",
            name: "",
            code: "",
            description: ""
        });
    }
}

BlocFormStore.MODE = {
    ADD : "add",
    EDIT : "edit",
}

export default BlocFormStore;
