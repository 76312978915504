'use strict';

import { observable, configure, action, flow, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {values} from 'lodash';

import ReleasesStore from './ReleasesStore';
import { ANY_VERSION } from '../utils/Constants';

import theclaw from '../utils/Api';

class AddUpdateModuleFormStore {

    state = States.initial;

    formData = this._getDefaultFormData();

    constructor() {
        makeObservable(this, {
            state: observable,
            formData: observable,
            updateFormData: action,
            mount: action
        });

        this.blocCode = null;
        this.collective = null;

        // The release that this module version is associating with
        this.release = null;
        // The current module we're operating on
        this.module = null;
        // Current mode of the form - add or update
        this.mode = null;
        // The options available for version incrementing
        this.versionOptions = [];
    }

    mount(mode, module, release, collective, blocCode) {
        // clear any data from the last time this form was used
        this.formData = this._getDefaultFormData();

        this.mode = mode;
        this.module = module;
        this.release = release;
        this.collective = collective;
        this.blocCode = blocCode;

        this.initialize();
    }

    initialize = flow(function* () {
        this.state = States.pending;

        yield this._setVersionOptions().then(action(() => {
            // set the form data
            this.formData.name = this.module.name;
            this.formData.releaseId = this.release.entityId;
            this.formData.description = this.module.description ? this.module.description : "";

            // only set the version to the old one if it's actually an option
            if (this.versionOptions.includes(this.module.version)) {
                this.formData.version = this.module.version;
            } else if (this.versionOptions.includes(ANY_VERSION)) {
                // This module hasn't been released before, so the user can put whatever they want, but default to 1.0.0
                this.formData.version = this.module.version === "0.0.0" ? "1.0.0" : this.module.version;
            } else {
                this.formData.version = this.versionOptions[0];
            }
        }));

        this.state = States.done;
    }).bind(this);

    getState = () => this.state;

    /*
     * Updates our internal representation of the data in the form
     */
    updateFormData(property, value) {
        this.formData[property] = value;
    }

    /**
     * This is the starting formData object
     */
    _getDefaultFormData() {
        return ({
            name: "",
            releaseId: "",
            version: "",
            description: "",
        });
    }

    /*
     * Sets the version options for this release
     * @return Promise - once this promise has been fulfilled,
     * this.versionOptions will be set
     */
    _setVersionOptions() {
        // pull the version options from the api
        return theclaw.getVersionOptions(this.blocCode, this.collective.code, this.release.entityId).then(
            action(options => {
                this.versionOptions = values(options.modules[this.module.name]);
                this.versionOptions.sort();
            })
        )
    }

    submit = () => {
        if (this.mode === "add") {
            this.addModule();
        } else {
            this.updateModule();
        }
    }

    /**
     * Add this module to a future release
     */
    async addModule() {
        let releaseId = this.formData.releaseId;
        let moduleName = this.formData.name;
        try {
            ReleasesStore.setStatePending();
            await theclaw.addModuleToRelease(this.blocCode, this.collective.code, releaseId, this.formData);
            ReleasesStore.loadReleases(true);
        } catch (e) {
            console.error(`Failure updating module [${moduleName}] for release [${releaseId}]`);
            console.log(e)
        }
    }

    /**
     * Update this module
     */
    async updateModule() {
        let releaseId = this.formData.releaseId;
        let moduleName = this.formData.name;
        try {
            ReleasesStore.setStatePending();
            await theclaw.updateModuleForRelease(this.blocCode, this.collective.code, releaseId, this.formData);
            ReleasesStore.loadReleases(true);
        } catch (e) {
            console.error(`Failure updating module [${moduleName}] in release [${releaseId}]`);
            console.log(e)
        }
    }

    /**
     * Remove a module from a future release.
     * This doesn't use the store module or release so it can be called from a different form.
     */
    async removeModule(module, release) {
        try {
            ReleasesStore.setStatePending();
            // Do not use this.collective here as the store may not have been mounted.
            await theclaw.removeModuleFromRelease(release.blocCode, release.collectiveCode, release.entityId, module.entityId);
            ReleasesStore.loadReleases(true);
        } catch (e) {
            console.error(`Failure removing module [${module.name}] from release [${module.releaseId}]`)
            console.log(e)
        }
    }
}

export default AddUpdateModuleFormStore;
