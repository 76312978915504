'use strict';

import React from 'react';
import {observer} from 'mobx-react';
import Button from 'react-bootstrap/Button';

import AppStore from "@uw-it-sis/lib-react/lib/AppStore";

import ActionUtils from '../utils/ActionUtils';
import ReleasesStore from "../releases/ReleasesStore";

function ActionButton(props) {
    let action = props.action;

    let actionMetadata = action;
    if (typeof action == "string") {
        actionMetadata = ActionUtils.Actions[action] ?? ActionUtils.Actions.__DEFAULT__(action)
    }

    // If the user isn't a DEVOPS role, don't show any buttons
    if (! AppStore._hasRole("DEVOPS")) {
        return null;
    }

    let variant = action.variant || "primary";

    let ActionIcon = actionMetadata.glyph;

    // It's a bit hacky, but observe the state of the ReleasesStore and disable
    // buttons if it is pending. This way we don't have to replace the entire
    // releases table with a loading element and make things jump around
    // whenever you change something.
    //
    // Might be better to disable buttons in each form separately.
    return (
        <Button
            key={props.key}
            variant={variant}
            size="sm"
            disabled={ReleasesStore.state == "pending" || (props.disabled ?? false)}
            title={actionMetadata.title}
            onClick={props.onClick}
            className="text-nowrap"
            aria-haspopup={actionMetadata.haspopup}>
            <ActionIcon aria-hidden={true} className="fs-6 me-2 align-middle" />
            <span className="align-middle">{actionMetadata.label}</span>
        </Button>
    );
}

// Observer is just to disable buttons on the release form...
export default observer(ActionButton);
