'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import _ from 'lodash';
import { observable, configure, action, makeObservable } from "mobx";
import {observer} from 'mobx-react';
configure({enforceActions: 'always'});

import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import LineItem from './tables/LineItem';
import Badge from "react-bootstrap/Badge";

/**
 * The commits button/modal component.
 * @param props Properties from the parent component.
 */
class Commits extends Component {
    static propTypes = {
        module: PropTypes.object.isRequired
    }

    isOpen = false;

    closeModal = () => this.isOpen = false;
    openModal = () => this.isOpen = true;

    constructor(props) {
        super(props);

        makeObservable(this, {
            isOpen: observable,
            closeModal: action,
            openModal: action
        });
    }

    render() {
        let commits = getCommits(this.props.module);

        if (commits.length === 0) {
            return null
        } else {
            let modal =
                <Modal
                    size="lg"
                    show={this.isOpen}
                    scrollable
                    centered
                    onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Commits - {this.props.module.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <CommitsList commits={commits} />
                    </Modal.Body>
                </Modal>;

            let button =
                <Button
                    variant="outline-dark"
                    title="Click to see a list of commit messages."
                    size="sm"
                    onClick={this.openModal}>
                    {commits.length}
                </Button>;

            return (
                <>
                    <LineItem name="Commits:" val={button} />
                    {modal}
                </>
            )
        }

    }
}


/*
 * Returns the commit objects to be shown
 * Returns the empty list of there's nothing to show
 */
function getCommits(module) {
    if (module.repositoryMetadata && ! module.repositoryMetadata.merged) {
        let commitsSorted = _.sortBy(module.repositoryMetadata.unmergedCommits, c => c.commitTime)
        return commitsSorted.reverse();
    } else {
        return []
    }
}

/*
 * A Component that displays the list of commits to a module
 * Requires the commits prop
 * Will return an empty ListGroup if there are no commits
 */
function CommitsList(props) {
    let listItems = props.commits.map( (commit, index) => {
            // Create a sensible date in our timezone.
            let commitDate = new Date(0);
            commitDate.setUTCSeconds(commit.commitTime);

            return (
                <ListGroup.Item key={index}>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="h4 mb-0">{commit.committer}</span>
                        <small className="text-muted">{moment(commitDate).format("L LTS")}</small>
                    </div>
                    <div className="d-flex align-items-baseline">
                        <Badge bg="light-gray" pill className="border me-3 text-dark">{commit.id.slice(0,8)}</Badge>
                        <span>{commit.message}</span>
                    </div>
                </ListGroup.Item>
            );
        })

    return (
        <ListGroup>
            {listItems}
        </ListGroup>
    )
}

export default observer(Commits);
