'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { observable, configure, action, makeObservable } from "mobx";
import {observer} from 'mobx-react';
configure({enforceActions: 'always'});

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

export class ConfirmModalStore {
    isOpen = false;

    closeModal = () => this.isOpen = false;
    openModal = () => this.isOpen = true;

    constructor() {
        makeObservable(this, {
            isOpen: observable,
            closeModal: action,
            openModal: action
        });
    }
}


/*
 * A generic modal that asks the user to confirm their choice.
 */
export const ConfirmModal = observer(
    class ConfirmModal extends Component {
        /*
         * Props:
         *   @store - A basic store that controls whether the modal is open or closed
         *   @title - Title of the confirm modal
         *   @message - Message of the confirm modal
         *   @confirmText - The text to display on the "confirm" button
         *   @onConfirm - callback function that runs when the user presses "confirm"
         *   @style - a bootstrap style for the message and confirm button. Defaults to "danger"
         */
        static propTypes = {
            store: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
            message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
            confirmText: PropTypes.string.isRequired,
            onConfirm: PropTypes.func.isRequired,
            style: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
        }
        static defaultProps = {
            style: 'danger',
        }

        // Arrow syntax here means we don't have to rebind the function to this
        submit = () => {
            this.props.onConfirm();
            this.props.store.closeModal();
        }

        render() {
            let component = null;
            if (this.props.store.isOpen) {
                component =
                    <Modal show={this.props.store.isOpen}
                           onHide={this.props.store.closeModal}
                           centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert variant={this.props.style}>{this.props.message}</Alert>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-end">
                            <Button variant={this.props.style} onClick={this.submit}>
                                {this.props.confirmText}
                            </Button>
                            <Button variant="secondary" onClick={this.props.store.closeModal} type="reset">
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>;
            }

            return component;
        }
    }
);

export default {ConfirmModal, ConfirmModalStore};
