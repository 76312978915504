'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import Form from 'react-bootstrap/Form';
import { When } from 'react-if';

import ModuleFormStore from './ModuleFormStore';
import ModuleStatus from './ModuleStatus';

import {
    ClawModal,
    ClawModalHeader,
    ClawModalBody,
    ClawModalFooter,
    ClawForm,
} from '../components/ClawModalLib';

/*
 * This Form is used to add or edit modules
 */
class ModuleForm extends Component {

    static propTypes = {
        mode: PropTypes.string.isRequired,
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object.isRequired,
        module: PropTypes.object, // null if we're creating a new module
    }

    constructor(props) {
        super(props);
        this.store = new ModuleFormStore();
    }

    componentDidMount() {
        this.store.mount(
            this.props.blocCode,
            this.props.collective,
            this.props.module,
            this.props.mode
        );
    }

    /**
     * Handle input widget changes.
     */
    handleFormChange = (e) => {
        let property = e.target.name;
        let value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        this.store.updateFormData(property, value);
    }

    _buildModalBody = () => {

        return (
            <ClawForm
                onSubmit={this.store.submit}
                state={this.store.state}>

                {/*
                  * MODULE NAME
                  */}
                <Form.Group controlId="name" >
                    <Form.Label>Module Name</Form.Label>
                    <Form.Control
                            required
                            name="name"
                            type="text"
                            value={this.store.formData.name}
                            placeholder="Foo"
                            onChange={this.handleFormChange} />
                    <Form.Control.Feedback />
                    <Form.Text>The name of the module.</Form.Text>
                </Form.Group>

                {/*
                  * MODULE STATUS
                  */}
                <When condition={this.store.mode === ModuleFormStore.MODE.ADD}>
                    <Form.Group controlId="status" >
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                            name="status"
                            onChange={this.handleFormChange}
                            defaultValue={ModuleStatus.ACTIVE}
                        >
                            <option key={1} value={"ACTIVE"}>Active</option>,
                            <option key={2} value={"INACTIVE"}>Inactive</option>
                        </Form.Select>
                        <Form.Control.Feedback />
                        <Form.Text>The url of the git repo.</Form.Text>
                    </Form.Group>
                </When>

                {/*
                  * MODULE GIT URL
                  */}
                <Form.Group controlId="gitUrl" >
                    <Form.Label>Git URL</Form.Label>
                    <Form.Control
                            required
                            name="gitUrl"
                            type="text"
                            value={this.store.formData.gitUrl}
                            placeholder="git@github.com:uw-it-sis/foo"
                            onChange={this.handleFormChange} />
                    <Form.Control.Feedback />
                    <Form.Text>The url of the git repo.</Form.Text>
                </Form.Group>

                {/*
                  * BUILD PRIORITY
                  */}
                <When condition={this.props.collective.buildSequencingEnabled}>
                    <Form.Group controlId="buildPriority" >
                        <Form.Label>Build Priority</Form.Label>
                        {/* TODO make this only allow numbers */}
                        <Form.Control
                                required
                                name="buildPriority"
                                type="text"
                                value={this.store.formData.buildPriority}
                                placeholder="50"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>Build priority of this module. Modules with the same priority will be grouped into waves. Lower priority builds first. Not applicable if build sequencing is turned off at the collective level.</Form.Text>
                    </Form.Group>
                </When>

                {/*
                  * REQUIRED
                  */}
                <Form.Group controlId="buildMonitoringEnabled" >
                    <Form.Label>Required</Form.Label>
                    <Form.Check type="switch" id="required">
                        <Form.Check.Input type="checkbox"
                            name="required"
                            checked={this.store.formData.required}
                            onChange={this.handleFormChange} />
                        <Form.Check.Label>Include in every release</Form.Check.Label>
                        <br/>
                        <Form.Text>Set to true if this module should be included in every release</Form.Text>
                    </Form.Check>
                </Form.Group>

                {/*
                  * BUILD MONITORING
                  */}
                <When condition={this.props.collective.buildSequencingEnabled}>
                    <Form.Group controlId="buildMonitoringEnabled" >
                        <Form.Label>Build Status Reported</Form.Label>
                        <Form.Check
                            type="switch"
                            id="buildMonitoringEnabled"
                        >
                            <Form.Check.Input type="checkbox"
                                name="buildMonitoringEnabled"
                                checked={this.store.formData.buildMonitoringEnabled}
                                onChange={this.handleFormChange} />
                            <Form.Check.Label>Build Status Reported</Form.Check.Label>
                            <br/>
                            <Form.Text>Set to true if this module has a build that will report its result to The Claw. If this is set to false, we will assume that the module build was successful, immediately after merging.</Form.Text>
                        </Form.Check>
                    </Form.Group>
                </When>

            </ClawForm>
        )
    }

    render() {
        let modalTitle;
        switch(this.store.mode) {
            case ModuleFormStore.MODE.ADD: modalTitle = "Create Module"; break;
            case ModuleFormStore.MODE.UPDATE: modalTitle = `Edit Module`; break;
        }

        return (
            <ClawModal id="ModuleFormModal">
                <ClawModalHeader title={modalTitle}/>
                <ClawModalBody>
                    {this._buildModalBody()}
                </ClawModalBody>
                <ClawModalFooter
                    onSubmit={this.store.submit}
                    loadingState={this.store.state}
                    canSubmit={this.store.isValid()}
                    />
            </ClawModal>
        );
    }
}

export default observer(ModuleForm);
