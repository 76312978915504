'use strict';

import React, { Component } from 'react'
import ReleasesStore from "../ReleasesStore";
import ActionUtils from '../../utils/ActionUtils';
import ActionButton from '../../components/ActionButton';
import {ConfirmModal, ConfirmModalStore} from "../../components/ConfirmModal";

class ResetAllForm extends Component {

    constructor(props) {
        super(props);
        this.store = new ConfirmModalStore();
    }

    render() {
        return (
            <>
                <ActionButton
                    action={ActionUtils.Actions.RESET_ALL_REPOS}
                    onClick={this.store.openModal}
                    />
                <ConfirmModal
                    store={this.store}
                    title={`Clobber Repos?`}
                    message={`This will destroy The Claw's working copy of ALL repos and re-clone them from the origin. Any local changes will be lost. Are you sure?`}
                    confirmText="Clobber All"
                    onConfirm={() => ReleasesStore.resetAllRepos()} />
            </>
        )
    }
}

export default ResetAllForm;
