'use strict';

/**
 * Utility class for git state.
 */
const RepoState = {
    IDLE: "IDLE",
    NEW: "NEW",
    UPDATING: "UPDATING",
    INITIALIZING: "INITIALIZING",
    RESETTING: "RESETTING",
    BROKEN: "BROKEN"
};

module.exports = RepoState;