'use strict';

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import classnames from "classnames";

import {configure} from "mobx";
import {observer} from 'mobx-react';
configure({enforceActions: 'always'});

import _ from 'underscore';

import { FaCircleNotch } from "react-icons/fa";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {When, If, Then, Else} from 'react-if';

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

/*
 * THE CLAW MODAL LIB
 * ==================
 *
 * This file contains a small library of modal components to encapsulate the common behavior for modals in The Claw.
 * Most components in this file simply wrap their respective React-Bootstrap components with a set of defaults.
 *
 * In order to simplify the modal's open/close state, the "closeModal" callback is passed to these components through a
 * react context. This means if you opt to not use ActionPopupButton to trigger the modal render, some other parent
 * component will need to set the context.
 */

////////////////////////////////////////////////////////////////////////
//                          UTILITY EXPORTS                           //
////////////////////////////////////////////////////////////////////////

/*
 * A react context to allow the closeModal callback to be automatically propagated to comopnents that need it.
 */
export const ClawModalContext = React.createContext({
    closeModal: () => { },
});

/**
 * FormStores can throw this when a form submission fails to suppress the modal from printing the caught error. In the
 * future, if we move error display into this lib, this error can be used to trigger the error render.
 */
export class ModalSubmitError extends Error {
    constructor(message, options) {
        super(message, options);
    }
}

////////////////////////////////////////////////////////////////////////
//                             COMPONENTS                             //
////////////////////////////////////////////////////////////////////////

/**
 * A wrapper for react-bootstrap/Modal
 * This automatically handles closing the modal and has some sensible defaults.
 *
 * ClawModals (and the forms they contain) are ALWAYS rendered; it is up to parent compnents (usually ActionPopupButton)
 * to conditionally render the modal if they should be hidden.
 */
export class ClawModal extends Component {
    static contextType = ClawModalContext;
    render() {
        return (
            <Modal
                id={this.props.id}
                show={true}
                onHide={this.context.closeModal}
                scrollable={this.props.scrollable || false}
                centered={true}>
                {this.props.children}
            </Modal>
        )
    }
}

/**
 * A wrapper for react-bootstrap/Modal.Body
 */
export class ClawModalBody extends Component {
    render() {
        return (
            <Modal.Body>
                {this.props.children}
            </Modal.Body>
        );
    }
}

/**
 * A wrapper for react-bootstrap/Modal.Header
 *
 * The title of the modal can be passed as a string using the `text` prop, or as a child component.
 */
export class ClawModalHeader extends Component {
    render() {
        return (
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.props.title}
                    {this.props.children}
                </Modal.Title>
            </Modal.Header>
        )
    }
}

/**
 * A wrapper for react-bootstrap/Modal.Footer
 *
 * The behavior of the footer can vary depending on what props are passed:
 *
 * Required props:
 *   - onSubmit:  Called when the "submit" button is pressed. The modal will wait to close if this is async or returns a promise.
 *
 * Optional props:
 *   - onReset: A callback that, when provided, adds a "reset" button to trigger the callback
 *   - formStoreState: If this is "initial" or "pending", renders a spinner instead of a submit button. Defaults to "done"
 *   - canSubmit: Disables the "submit" button when false
 *   - submitText: The text to render on the "submit" button instead of "Submit"
 */
@observer
export class ClawModalFooter extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        formStoreState: PropTypes.string,
        canSubmit: PropTypes.bool,
        submitText: PropTypes.string,
    }
    static contextType = ClawModalContext;
    static NOP = () => { }; // a do nothing function

    render() {

        let onSubmit = wrapSubmitCallback(this.context, this.props.onSubmit) ?? (() => window.alert("onSubmit callback not provided"));
        let onReset = this.props.onReset; // Don't render a reset button if undefined
        let formStoreState = this.props.loadingState ?? States.done; // Default to done
        let canSubmit = this.props.canSubmit ?? true; // Default to true
        let submitText = this.props.submitText ?? "Submit"

        let closeModal = this.context.closeModal;

        let footer = null;
        switch (formStoreState) {
            case States.initial:
            case States.pending:
                // Show a spinner
                footer = (
                    <Modal.Footer className="justify-content-end">
                        <div className="text-muted d-flex align-items-center space-x-2" style={{padding: '0.3rem 0.75rem'}}>
                            <FaCircleNotch aria-hidden={true} className="icon-spin" />
                            <small className="fw-bold">Loading ...</small>
                        </div>
                    </Modal.Footer>
                )
                break;
            default:
                footer = (
                    <Modal.Footer className="justify-content-end">
                        {/* Only render the reset button if the method is defined on the store */}
                        <When condition={onReset != undefined}>
                            <Button variant="secondary" onClick={onReset} type="reset">Reset</Button>
                        </When>
                        <Button variant="primary"
                                disabled={!canSubmit}
                                className={classnames({"disabled":!canSubmit})}
                                aria-disabled={!canSubmit}
                                onClick={onSubmit}>
                            {submitText}
                        </Button>
                        <Button variant="secondary" onClick={closeModal} type="reset">
                            Cancel
                        </Button>
                    </Modal.Footer>
                )
        }
        return footer;
    }
}

/**
 * A wrapper for react-bootstrap/Form.
 *
 * Helps to make form submission more consistent.
 */
export class ClawForm extends Component {
    static contextType = ClawModalContext;
    render() {
        // Pull the onSubmit out of the props (we don't want to pass to Form as-is)
        let {
            onSubmit,
            ...restProps
        } = this.props;

        // Disable form inputs while the form is submitting
        let disabled = false;
        switch (this.props.state ?? States.done) {
            case States.initial:
            case States.pending:
                disabled = true; break;
            default:
                disabled = false;
        }
            
        return (
            <Form onSubmit={wrapSubmitCallback(this.context, onSubmit)} {...restProps} >
                {/* There seems to be a bug with the fieldset tag, where the
                transition from "disabled" to "enabled" breaks some form
                fields... like you can't type anything in. So don't render the
                fieldset tag when the form is enabled. */}
                <If condition={disabled}>
                    <Then>
                        <fieldset disabled={disabled}>
                            {this.props.children}
                        </fieldset>
                    </Then>
                    <Else>
                            {this.props.children}
                    </Else>
                </If>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////
//                        LOCAL UTIL FUNCTIONS                        //
////////////////////////////////////////////////////////////////////////

/**
 * Wrap a submit callback to close the modal when finished.
 *
 * If the callback is an async function or returns a promise we will wait until the callback finishes to close the
 * modal.
 *
 * If the callback throws a ModalSubmitError error, we won't close the modal. Potentially in the future, we could have
 * the modal display the errorMessage instead of the respective forms.
 */
function wrapSubmitCallback(context, callback) {

    // Wrap submit callbacks to make form submission more consistent...
    return async (event) => {
        // Prevent page refresh
        event.preventDefault();

        // Call the form's main submit method... probably "this.store.submit" or similar
        try {
            await callback();

            // Close the modal after submitting the form
            context.closeModal();
        } catch (e) {
            if (e instanceof ModalSubmitError) {
                // Don't print the error.
                // In the future we could use this error to move the error display out of the respective form classes,
                // and into the modal class.
            } else {
                console.log(`Modal onSubmit caught error: `, e);
            }
        }
    }

}

